import Loading from '@common_loaders/Backdrop';
import Alert from '@material-ui/lab/Alert';
import parse from 'html-react-parser';
import CmsBlockWidget from '@modules/blog/components/CmsBlockWidget';
import ProductSliderWidget from '@modules/blog/components/ProductSliderWidget';
import { useRouter } from 'next/router';
import CmsTab from './CmsTab';
import useStyles from './style';

const CmsPage = (props) => {
    const {
        data, t, loading, error, storeConfig,
    } = props;
    const router = useRouter();
    const styles = useStyles();
    const isMerchantPage = router.asPath.includes('/merchant-page');
    if (error) {
        return (
            <Alert className="m-15" severity="error">
                {t('common:error:fetchError')}
            </Alert>
        );
    }
    if (loading) return <Loading open={loading} />;

    const { content, custom_content } = data.cmsPage;
    let updatedContent = '';
    if (isMerchantPage) {
        updatedContent = custom_content.includes('widget') ? custom_content.replace(/{{widget/g, '<pwa').slice(0, -2).concat(' />') : custom_content;
    } else {
        updatedContent = content.includes('widget') ? content.replace(/{{widget/g, '<pwa').slice(0, -2).concat(' />') : content;
    }

    const options = {
        replace: (domNode) => {
            if (domNode.attribs && domNode.attribs.class === 'scroll-into-page') {
                const { href, title: titleAnchor, ...other } = domNode.attribs;
                const handleScroll = (e) => {
                    e.preventDefault();
                    let section = document.querySelector(href);
                    const childOffset = 5;
                    // eslint-disable-next-line no-plusplus
                    for (let i = 0; i < childOffset; i++) {
                        if (!section.previousElementSibling) {
                            break;
                        }
                        section = section.previousElementSibling;
                    }
                    section.scrollIntoView({ behavior: 'smooth' });
                };
                return (
                    <a onClick={handleScroll} href={href} {...other}>
                        {titleAnchor}
                    </a>
                );
            }
            if (domNode.attribs && domNode.attribs.class === 'custom-tab') {
                const jsonData = domNode?.children?.[0]?.data?.trim()?.replace(/\n/g, '') || '';
                const tabData = JSON.parse(jsonData);
                return <CmsTab data={tabData?.data || []} />;
            }
            if (domNode?.attribs?.type === 'Magento\\CatalogWidget\\Block\\Product\\ProductsList') {
                const propsWidget = domNode.attribs;
                return <ProductSliderWidget storeConfig={storeConfig} {...propsWidget} />;
            }
            if (domNode?.attribs?.type === 'Magento\\Cms\\Block\\Widget\\Block') {
                const propsWidget = domNode.attribs;
                return <CmsBlockWidget storeConfig={storeConfig} {...propsWidget} />;
            }

            return null;
        },
    };

    return (
        <>
            <div className="cms-container">
                {/* eslint-disable-next-line react/no-danger */}
                <h4 className={styles.titleCms} dangerouslySetInnerHTML={{ __html: data.cmsPage.title }} />
                <div className="content">{parse(updatedContent, options)}</div>
            </div>
        </>
    );
};

export default CmsPage;
