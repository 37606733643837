/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { modules, debuging } from '@config';
import { getLoginInfo } from '@helper_auth';
import { setCookies, getCookies } from '@helper_cookies';
import { useTranslation } from '@i18n';
import route from 'next/router';
import React from 'react';
import { setResolver, getResolver } from '@helper_localstorage';
import classNames from 'classnames';
import ConfigurableOpt from '@core_modules/catalog/plugin/ProductItem/components/ConfigurableProductItem';
import { useQuery } from '@apollo/client';
import { localCompare } from '@modules/theme/services/graphql/local';
import TagManager from 'react-gtm-module';
import dynamic from 'next/dynamic';
import { addWishlist, addCompare } from '../../services/graphql';
import useStyles from './style';

const WeltpixelLabel = dynamic(() => import('./components/WeltpixelLabel'), { ssr: false });

const ProductItem = (props) => {
    const styles = useStyles();
    const { t } = useTranslation(['catalog']);
    const [feed, setFeed] = React.useState(false);
    const [spesificProduct, setSpesificProduct] = React.useState({});
    const {
        id, url_key = '', categorySelect, review, ImageProductView, DetailProductView, LabelView, className = '',
        weltpixel_labels, ...other
    } = props;
    const { storeConfig } = other;
    const [vendorData] = other.vendor_product_data;

    let isLogin = '';
    if (typeof window !== 'undefined') isLogin = getLoginInfo();
    const [postAddWishlist] = addWishlist();
    const [postAddCompare] = addCompare();
    const { data: dataCompare, client } = useQuery(localCompare);

    const handleFeed = (itemProps) => {
        if (isLogin && isLogin !== '') {
            postAddWishlist({
                variables: {
                    productId: id,
                },
            }).then(async () => {
                // clickstream
                if (storeConfig?.enabled_clickstream === '1' && typeof window !== 'undefined' && window?._swan?.productAddedToWishlist) {
                    window._swan.productAddedToWishlist({ productId: itemProps.sku });
                }

                TagManager.dataLayer({
                    dataLayer: {
                        ecommerce: {
                            action: {
                                items: [
                                    {
                                        currency: itemProps.price_range.minimum_price.regular_price.currency,
                                        item_name: itemProps.name,
                                        item_id: itemProps.sku,
                                        price: itemProps.price_range.minimum_price.regular_price.value || 0,
                                        item_category: itemProps.categories.length > 0 ? itemProps.categories[0].name : '',
                                    },
                                ],
                            },
                        },
                        event: 'add_to_wishlist',
                    },
                });
                await setFeed(!feed);
                await window.toastMessage({ open: true, variant: 'success', text: t('common:message:feedSuccess') });
                route.push('/wishlist');
            })
                .catch((e) => {
                    window.toastMessage({
                        open: true,
                        variant: 'error',
                        text: debuging.originalError ? e.message.split(':')[1] : t('common:message:feedFailed'),
                    });
                });
        } else if (typeof window.toastMessage !== 'undefined') {
            window.toastMessage({
                open: true,
                variant: 'warning',
                text: t('catalog:wishlist:addWithoutLogin'),
            });
        }
    };

    const getUid = () => {
        const uidCompare = getCookies('uidCompare');
        if (uidCompare) {
            return uidCompare;
        }
        const newUid = `${new Date().getTime().toString(36)}${(Math.random()).toString(36).replace('.', '')}`;
        setCookies('uidCompare', newUid);
        return newUid;
    };

    const handleCompare = () => {
        postAddCompare({
            variables: {
                productId: id,
                vendorId: vendorData.vendor_id,
                uid: isLogin ? '' : getUid(),
            },
        })
            .then(async () => {
                await window.toastMessage({ open: true, variant: 'success', text: t('common:message:compareSuccess') });
                client.writeQuery({ query: localCompare, data: { totalCompare: dataCompare.totalCompare + 1 } });
            })
            .catch((e) => {
                window.toastMessage({
                    open: true,
                    variant: 'error',
                    text: debuging.originalError ? e.message.split(':')[1] : t('common:message:compareFailed'),
                });
            });
    };

    const handleClick = async (itemProps) => {
        // Clickstream
        if (storeConfig?.enabled_clickstream === '1' && typeof window !== 'undefined' && window?._swan?.productClicked) {
            window._swan.productClicked({ productId: itemProps.sku });
        }

        // GA 4 dataLayer
        TagManager.dataLayer({
            dataLayer: {
                event: 'select_item',
                ecommerce: {
                    action: {
                        items: [
                            {
                                currency: itemProps.price_range.minimum_price.regular_price.currency,
                                item_name: itemProps.name,
                                item_id: itemProps.sku,
                                price: itemProps.price_range.minimum_price.regular_price.value || 0,
                                item_category: itemProps?.categories?.length > 0 ? itemProps.categories[0].name : '',
                            },
                        ],
                    },
                },
            },
        });

        const urlResolver = getResolver();
        urlResolver[`/${url_key}`] = {
            type: 'PRODUCT',
        };
        await setResolver(urlResolver);
        setCookies('lastCategory', categorySelect);
        route.push('/[...slug]', `/${url_key}`);
    };

    const ratingValue = review && review.rating_summary ? parseInt(review.rating_summary, 0) / 20 : 5;
    const DetailProps = {
        spesificProduct,
        handleClick,
        handleFeed,
        handleCompare,
        ratingValue,
        feed,
    };
    return (
        <>
            <div className={classNames(styles.itemContainer, className)}>
                {
                    modules.catalog.productListing.label.enabled && LabelView ? (
                        <LabelView {...other} spesificProduct={spesificProduct} />
                    ) : null
                }
                <div className={styles.imgItem}>
                    <WeltpixelLabel t={t} weltpixel_labels={weltpixel_labels} categoryLabel />
                    <ImageProductView handleClick={() => handleClick(props)} spesificProduct={spesificProduct} url_key={url_key} {...other} />
                </div>
                <div className={styles.detailItem}>
                    <DetailProductView {...DetailProps} {...other} url_key={url_key} />
                    {modules.catalog.productListing.configurableOptions.enabled ? (
                        <ConfigurableOpt setSpesificProduct={setSpesificProduct} {...other} />
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default ProductItem;
