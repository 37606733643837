/* eslint-disable no-else-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { getProduct } from '@modules/catalog/services/graphql';
import { getVendor } from '@helper_cookies';
import CarouselV2 from '@commons/CarouselV2';
import ProductCard from '@modules/home/pages/default/components/ProductCard';
import React from 'react';

const getDataCondition = ({ condition_object }) => {
    let conditions = {};
    for (const condition_index in condition_object) {
        const condition_item = condition_object[condition_index];
        const attribute = condition_item?.attribute;
        const aggregator = condition_item?.aggregator;
        if (attribute !== undefined) conditions = { ...conditions, attribute, attribute_value: condition_item?.value };
        if (aggregator !== undefined) conditions = { ...conditions, aggregator, aggregator_value: condition_item?.value };
    }
    return conditions;
};

const ProductSliderWidget = (props) => {
    const {
        products_count,
        conditions_encoded,
        storeConfig,
    } = props;

    const condition_object = JSON.parse(conditions_encoded.replace(/`/g, '"').replace(/\^/g, '').replace(/\[/g, '{').replace(/]/g, '}'));
    const dataCondition = getDataCondition({ condition_object });

    let dataLoading = true;
    let dataItems = [];

    if (dataCondition.attribute === 'category_ids') {
        const { loading, data } = getProduct(
            {
                search: '',
                filter: [
                    {
                        type: 'category_id',
                        value: dataCondition.attribute_value,
                    },
                ],
                vendorId: Number(getVendor()),
            },
            {
                variables: {
                    pageSize: products_count,
                    currentPage: 1,
                },
            },
        );
        dataLoading = loading;
        dataItems = data?.products?.items;
    }

    if (!dataLoading && dataItems?.length > 0) {
        return (
            <div>
                <CarouselV2>
                    {dataItems.map((product) => (
                        <ProductCard
                            key={product.id}
                            {...product}
                            vendor_product_data={product.vendor_product_data}
                            customGtmClass="gtm_mitra10_cta_swipe_product"
                            storeConfig={storeConfig}
                        />
                    ))}
                </CarouselV2>
            </div>
        );
    }

    return null;
};

export default ProductSliderWidget;
