import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    dialogWrapper: {
        '& .MuiDialog-paperScrollPaper': {
            borderRadius: '8px',
            maxWidth: '450px',
            '@media (max-width: 767px )': {
                margin: 16,
            },
        },
    },
    dialogTitle: {
        padding: 30,

        '& h2': {
            display: 'flex',
            aligItems: 'center',
            fontWeight: 600,
        },
        '& span': {
            flex: 1,
        },
        '@media (max-width: 767px )': {
            padding: 16,
        },
    },
    dialogContent: {
        padding: '0 30px 30px 30px',

        '& p': {
            color: '#1D2939',
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '20px',
            margin: 0,
        },
        '@media (max-width: 767px )': {
            padding: '0 16px 16px 16px',
        },
    },
    dialogAction: {
        padding: '0 30px 30px 30px',
        display: 'flex',
        gap: '16px',

        '& button': {
            boxShadow: 'unset',
            padding: '12px 16px',
            minWidth: '100px',
            minHeight: '48px',
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '20px',
            letterSpacing: '0.35px',
        },
        '@media (max-width: 767px )': {
            padding: '0 16px 16px 16px',
        },
    },
    actionCancel: {
        background: '#fff',
        border: '1px solid #2E3092',
        color: '#2E3092',

        '&:hover': {
            background: '#fff',
            border: '1px solid #2E3092',
            color: '#2E3092',
        },
    },
    actionConfirm: {
        background: '#2E3092',
        color: '#fff',
        border: '1px solid #2E3092',
        '&:hover': {
            background: '#2E3092',
            color: '#fff',
            border: '1px solid #2E3092',
        },
    },
}));

export default useStyles;
