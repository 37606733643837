/* eslint-disable react/jsx-one-expression-per-line */
import classNames from 'classnames';
// import { makeStyles } from '@material-ui/core/styles';
// import { ORANGE_FLASHSALE } from '@theme_color';

import Box from '@material-ui/core/Box';
import Typography from '@common_typography';
import useStyles from '../style';

// const useStyles = makeStyles(() => ({
//     flashBox: {
//         borderRadius: '8px',
//         marginBottom: '15px',
//         padding: '10px',
//         display: 'flex',
//         flexDirection: 'row',
//         justifyContent: 'space-between',
//         '@media (min-width: 320px )': {
//             '& h1': {
//                 fontSize: 14,
//                 color: 'white',
//             },
//         },
//         '@media (min-width: 768px )': {
//             marginTop: 25,
//             '& h1': {
//                 fontSize: 16,
//             },
//         },
//         '@media (min-width: 1440px )': {
//             '& h1': {
//                 fontSize: 18,
//             },
//         },
//     },
// }));

const View = (props) => {
    const styles = useStyles();
    const { /* t, */ time } = props;

    const getTextTime = (number) => (number > 9 ? number : (`0${number || 0}`));

    return (
        <Box className={classNames(styles.timerFlashPdp)}>
            <div className={styles.labelFlashSale}>
                <Typography variant="p" className={styles.titleFlash}>
                    <div className={classNames(styles.endsIn, styles.flashTitleWrapper)}>
                        <img alt="" src="/assets/img/pdp/Flash.svg" />
                        <span className={classNames(styles.endsInFlash, styles.flashSaleText)}>FLASH SALE</span>
                    </div>
                </Typography>
            </div>
            <div>
                <Typography variant="p" align="right" className={styles.timeWrapperPdp}>
                    {/* {t('home:endsIn')} {' '} */}
                    {/* <div className={styles.timeContainer}>
                        <span className={classNames(styles.time, styles.timeCenter)}>
                            {getTextTime(time && time.days)}
                        </span>
                    </div> */}
                    <div className={classNames(styles.timeContainer, 'pdpTime')}>
                        <span className={classNames(styles.time, styles.timeCenter)}>
                            {getTextTime(time && time.hours)}
                        </span>
                    </div>
                    <div className={classNames(styles.timeContainer, 'pdpTime')}>
                        <span className={classNames(styles.time, styles.timeCenter)}>
                            :
                        </span>
                    </div>
                    <div className={classNames(styles.timeContainer, 'pdpTime')}>
                        <span className={classNames(styles.time, styles.timeCenter)}>
                            {getTextTime(time && time.minutes)}
                        </span>
                    </div>
                    <div className={classNames(styles.timeContainer, 'pdpTime')}>
                        <span className={classNames(styles.time, styles.timeCenter)}>
                            :
                        </span>
                    </div>
                    <div className={classNames(styles.timeContainer, 'pdpTime')}>
                        <span className={classNames(styles.time, styles.timeCenter)}>
                            {getTextTime(time && time.seconds)}
                        </span>
                    </div>
                </Typography>
            </div>
        </Box>
    );
};

export default View;
