import { gql } from '@apollo/client';

export const getCmsPage = gql`
    query($identifier: String!) {
        cmsPage(identifier: $identifier) {
            content
            content_heading
            identifier
            meta_description
            meta_keywords
            meta_title
            page_layout
            title
            url_key
            custom_content
        }
    }
`;

export default { getCmsPage };
