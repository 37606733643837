/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import Button from '@common_button';
import {
    setVendor,
    setVendorApp,
    setVendorCode,
    setVendorCodeApp,
} from '@helper_cookies';
import isMobileApp from '@root/src/helpers/userAgent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import useStyles from './style';

const orderVendorName = (arrayStore) => {
    if (arrayStore.length > 0) {
        // eslint-disable-next-line no-nested-ternary
        return arrayStore.sort((a, b) => ((a.vendor_name < b.vendor_name) ? -1 : ((b.vendor_name > a.vendor_name) ? 1 : 0)));
    }

    return [];
};

const StoresDialog = (props) => {
    const classes = useStyles();
    const {
        t,
        onClose,
        open,
        availableSource = [],
        isLayananInstalasi,
        setSelectedVendorById,
    } = props;

    const orderAvailableStore = orderVendorName(availableSource);

    const handleShopHere = (id, storeCode) => {
        setSelectedVendorById(id);
        if (isMobileApp === 'app') {
            setVendorApp(id);
            setVendorCodeApp(storeCode);
        } else {
            setVendor(id);
            setVendorCode(storeCode);
        }
        onClose();
        if (typeof window !== 'undefined') {
            window.location.reload();
        }
    };

    return (
        <Dialog className={classes.containerDialog} onClose={onClose} aria-labelledby="stores-dialog-title" open={open}>
            <DialogTitle className={classes.dialogTitle} id="stores-dialog-title">
                <span>
                    {t('product:findAStore')}
                </span>
                <CloseIcon
                    className={classes.closeIcon}
                    onClick={onClose}
                />
            </DialogTitle>
            <DialogContent dividers>
                {orderAvailableStore.map((data, i) => (
                    <div key={i} className={classNames(classes.item, 'row')}>
                        <div className={classNames(classes.itemLeft, 'col-xs-12 col-sm-9')}>
                            <div className={classes.storeTitleContainer}>
                                <div className={classes.storeName}>{data?.vendor_name?.toLowerCase() || ''}</div>
                                {!isLayananInstalasi && !data.preorder && (
                                    <div className={classes.avaialable}>
                                        <CheckCircleIcon className="checkIcon" />
                                        <span>
                                            {data.stock_qty || 0} {t('product:avaialable')}
                                        </span>
                                    </div>
                                )}

                            </div>
                            <div className={classes.street}>{data.street}</div>
                        </div>
                        <div className={classNames(classes.itemRight, 'col-xs-12 col-sm-3')}>
                            <Button
                                className={classes.buttonShopHere}
                                color="primary"
                                onClick={() => handleShopHere(data.vendor_id, data.store_code)}
                            >
                                {t('product:shopHere')}
                            </Button>
                        </div>
                    </div>
                ))}
            </DialogContent>
        </Dialog>
    );
};

export default StoresDialog;
