/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import classNames from 'classnames';
import LeftArrowIcon from '@material-ui/icons/ArrowBackIos';
import RightArrowIcon from '@material-ui/icons/ArrowForwardIos';
import Slider from 'react-slick';
import Zoom from 'react-prismazoom';
import useStyles from './style';
import Thumbor from '../Banner/Thumbor';
import ImageSlide from '../Banner/ImageSlide';

const Banner = ({
    data = [],
    height,
    width,
    contentWidth = '',
    autoPlay = true,
    noLink = false,
    thumbnail = false,
    showArrow = true,
    speed = 500,
    autoplaySpeed = 4000,
    actionImage = () => { },
    zoom = false,
    zoomRef = null,
    children,
    useDots = true,
}) => {
    const styles = useStyles();
    const [slideIndex, setIndex] = useState(0);
    const [count, setCount] = useState(0);
    let sliderRef = React.createRef();
    const dotActive = data.length > 1
        ? classNames(styles.dotsItem, styles.dotActive)
        : styles.hide;
    const dotItem = data.length > 1 ? styles.dotsItem : styles.hide;
    const handleLeftArrow = () => {
        sliderRef.slickGoTo(slideIndex - 1);
    };
    const handleRightArrow = () => {
        sliderRef.slickGoTo(slideIndex + 1);
    };
    const settings = {
        // className: thumbnail ? 'slick-thumbnail' : 'slick-pwa',
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: autoPlay,
        speed,
        autoplaySpeed,
        afterChange: () => setCount(count + 1),
        beforeChange: (current, next) => setIndex(next),
        arrows: false,
    };

    const settingMultiple = {
        dots: false,
        infinite: false,
        speed: 500,
        rtl: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        centerMode: false,
    };

    return (
        <div className={zoom ? styles.container : styles.normalContainer}>
            {thumbnail && zoom ? (
                <div>
                    {data.map((item, id) => (
                        <div
                            className={slideIndex === id
                                ? classNames(styles.thumbnail, styles.thumbnailActive, 'hidden-mobile')
                                : classNames(styles.thumbnail, 'hidden-mobile')}
                            key={id}
                            onClick={() => {
                                sliderRef.slickGoTo(id);
                            }}
                        >
                            <Thumbor
                                useThumbor
                                src={item.imageUrl}
                                alt="thumbnail"
                                width={100}
                                height={100}
                                quality={100}
                                className={styles.thumbnailImg}
                            />
                        </div>
                    ))}
                </div>
            ) : null}
            {thumbnail && !zoom ? (
                <div className={styles.thumbnailContainer}>
                    <Slider
                        {...settingMultiple}
                    >
                        {data.map((item, id) => (
                            <div
                                className={slideIndex === id
                                    ? classNames(styles.normalthumbnail, styles.thumbnailNormalActive, 'hidden-mobile')
                                    : classNames(styles.normalthumbnail, 'hidden-mobile')}
                                key={id}
                                onClick={() => {
                                    sliderRef.slickGoTo(id);
                                }}
                            >
                                <Thumbor
                                    useThumbor
                                    src={item.imageUrl}
                                    alt="thumbnail"
                                    width={80}
                                    height={80}
                                    quality={100}
                                    className={styles.normalThumbnailImg}
                                />
                            </div>
                        ))}
                    </Slider>
                </div>
            ) : null}
            <div className={classNames(zoom ? styles.caraousel : styles.normalCarausel)}>
                {
                    zoom ? (
                        <Zoom ref={zoomRef}>
                            <Slider ref={(slider) => sliderRef = slider} {...settings}>
                                {data.map((item, key) => (
                                    <div onClick={actionImage} key={key}>
                                        <div className={styles.zoomWrapper}>
                                            <ImageSlide
                                                useThumbor
                                                height={height}
                                                customClass={styles.customClass}
                                                width={width}
                                                noLink={noLink}
                                                key={key}
                                                lazy={key !== 0}
                                                {...item}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </Zoom>
                    ) : (
                        <Slider ref={(slider) => sliderRef = slider} {...settings}>
                            {data.map((item, key) => (
                                <div onClick={actionImage} key={key}>
                                    <ImageSlide
                                        useThumbor
                                        height={height}
                                        customClass={styles.customNormalClass}
                                        width={width}
                                        noLink={noLink}
                                        key={key}
                                        lazy={key !== 0}
                                        {...item}
                                    />
                                </div>
                            ))}
                        </Slider>
                    )
                }
                {
                    showArrow ? (
                        <>
                            <div
                                className={thumbnail
                                    ? classNames(styles.arrow, styles.leftArrow, styles.leftArrowThumbnail)
                                    : classNames(styles.arrow, styles.leftArrow)}
                                onClick={handleLeftArrow}
                            >
                                <LeftArrowIcon fontSize="inherit" />
                            </div>
                            <div className={classNames(styles.arrow, styles.rightArrow)} onClick={handleRightArrow}>
                                <RightArrowIcon fontSize="inherit" />
                            </div>
                        </>
                    ) : null
                }
                {
                    useDots ? (
                        <div className={styles.dots}>
                            {data.map((item, id) => (
                                <div
                                    className={slideIndex === id ? dotActive : dotItem}
                                    key={id}
                                    onClick={() => {
                                        sliderRef.slickGoTo(data.length - (id + 1));
                                    }}
                                />
                            ))}
                        </div>
                    ) : null
                }
                {children}
            </div>
        </div>
    );
};
export default Banner;
