/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/order */
/* eslint-disable react/no-danger */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-duplicates */
import Button from '@common_button';
import PriceFormat from '@common_priceformat';
import Banner from '../../../../commons/Slick/BannerThumbnail';
import BannerMobile from '../../../../commons/Slick/BannerThumbnail';
// import Caraousel from '@common_slick/Caraousel';
// import CarouselSkeleton from '@common_slick/Caraousel/Skeleton';
import Typography from '@common_typography';
import IconButton from '@material-ui/core/IconButton';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorderOutlined from '@material-ui/icons/FavoriteBorderOutlined';
// import ShareOutlined from '@material-ui/icons/ShareOutlined';
// import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import classNames from 'classnames';
import ProductItem from '@core_modules/catalog/plugin/ProductItem';
import React, { useEffect } from 'react';
import { getHost } from '@helper_config';
import { getVendor as getVendorCookies } from '@helper_cookies';
import Breadcrumb from '@common_breadcrumb';
import FlashSaleTimer from '@common_flashsaletimer';
import FlashSaleTimerView from '@common_flashsaletimer/view/simplePdp';
// import RatingStar from '@common_ratingstar';
import { breakPointsUp } from '@helper_theme';
import dynamic from 'next/dynamic';
import useStyles from './style';
import ExpandDetail from '@modules/product/pages/default/components/ExpandDetail';
import ListReviewsMobile from '@modules/product/pages/default/components/ListReviewsMobile';
import OptionItem from '@modules/product/pages/default/components/OptionItem';
// import RightDrawer from '@core_modules/product/pages/default/components/RightDrawer';
// import SharePopup from '@modules/product/pages/default/components/SharePopup';
import ModalPopupImage from '@modules/product/pages/default/components/ModalPopupImage';
import ShippingMethodAndStore from './shippingMethodAndStore';
// import Modal from '@material-ui/core/Modal';
// import Backdrop from '@material-ui/core/Backdrop';
// import Fade from '@material-ui/core/Fade';
import Star from '@material-ui/icons/Star';
import Divider from '@material-ui/core/Divider';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Link from 'next/link';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import Dialog from '@material-ui/core/Dialog';
import Close from '@material-ui/icons/Close';
// import WeltpixelLabel from '@root/src/modules/catalog/plugin/ProductItem/components/WeltpixelLabel';
import { formatPrice } from '@helper_currency';

import PreOrderConfirmDialog from '@common_preorderconfirmdialog';

import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from 'react-share';

const DesktopOptions = dynamic(() => import('@modules/product/pages/default/components/OptionItem/DesktopOptions'), { ssr: true });
const TabsView = dynamic(() => import('./DesktopTabs'), { ssr: true });
// const ItemShare = dynamic(() => import('@modules/product/pages/default/components/SharePopup/item'), { ssr: true });
const Caraousel = dynamic(() => import('@common_slick/Caraousel'), { ssr: false });
const WeltpixelLabel = dynamic(() => import('@root/src/modules/catalog/plugin/ProductItem/components/WeltpixelLabel'), { ssr: false });
const XenditCalculator = dynamic(() => import('./XenditCalculator'), { ssr: true });
const IndodanaCalculator = dynamic(() => import('./IndodanaCalculator'), { ssr: true });
const ViewAgainProducts = dynamic(() => import('@modules/home/pages/default/components/ViewAgain'), { ssr: false });

const generateSoldProduct = (number) => (number >= 1000 ? `${(number / 1000).toFixed(0)}rb+` : number);

const ProductPage = (props) => {
    const styles = useStyles();
    const {
        t,
        data,
        getAvailableSource,
        openOption,
        handleOption,
        setOpenOption,
        setBanner,
        setPrice,
        // openShare,
        // setOpenShare,
        route,
        banner,
        // openDrawer,
        // setOpenDrawer,
        breadcrumbsData,
        price,
        handleWishlist,
        handleCompare,
        reviewValue,
        wishlist,
        expandData,
        relateData,
        weltpixel_labels,
        openImageDetail,
        handleOpenImageDetail,
        isLogin,
        storeConfig,
        handleYellowAiChat,
    } = props;
    const [openDetailDesc, setOpenDetailDesc] = React.useState(false);
    const [flashSaleData] = data.vendor_product_data;
    const startFlashSaleDate = new Date(flashSaleData.flash_sale_from).getTime();
    const endFlashSaleDate = new Date(flashSaleData.flash_sale_to).getTime();
    const isFlashSale = Date.now() > startFlashSaleDate && Date.now() < endFlashSaleDate && flashSaleData.show_countdown;
    const nowTime = new Date(Date.now()).getTime();
    const desktop = breakPointsUp('sm');

    const handleClickStreamShare = () => {
        if (storeConfig?.enabled_clickstream === '1' && typeof window !== 'undefined' && window?._swan?.share) {
            window._swan.share({ productId: data.sku });
        }
    };

    const favoritIcon = wishlist ? <Favorite className={styles.iconShare} /> : <FavoriteBorderOutlined className={styles.iconShare} />;
    const availableSource = getAvailableSource.data && getAvailableSource.data.getAvailableSource && getAvailableSource.data.getAvailableSource.list;
    const getStockVendor = () => {
        if (availableSource && availableSource.length) {
            const obj = availableSource.find((e) => e.vendor_id === getVendorCookies());
            return (obj && obj.stock_qty) || 0;
        }
        return 0;
    };

    let contentCaraousel = '';
    if (typeof window !== 'undefined' && relateData.length > 0) {
        contentCaraousel = <Caraousel data={relateData} Item={ProductItem} />;
    }

    const getPreorder = () => data && data.vendor_product_data && data.vendor_product_data[0] && data.vendor_product_data[0].preorder > 0;

    const getPreorderTime = () => {
        const tmp = data && data.vendor_product_data && data.vendor_product_data[0];
        if (tmp && tmp.preorder_time) {
            return tmp.preorder_time;
        }
        if (tmp && tmp.preorder_time_config) {
            return tmp.preorder_time_config;
        }
        return '7';
    };

    // React.useEffect(() => {
    //     const script = document.createElement('script');
    //     const script2 = document.createElement('script');

    //     script.type = 'text/javascript';
    //     script.dangerouslySetInnerHTML = {
    //         __html: 'var QUICK_AR_INIT = {key : "4LytRhdbKD"}',
    //     };
    //     script.async = true;
    //     script2.src = 'https://cdn-quick-ar.threedy.ai/latest/threedy.js';
    //     document.body.appendChild(script);
    //     document.body.appendChild(script2);

    //     return () => {
    //         document.body.removeChild(script);
    //         document.body.removeChild(script2);
    //     };
    // }, []);

    // modal share
    const [openModal, setOpenModal] = React.useState(false);

    // modal pre-order
    const [openPreorderPolicy, setOpenPreorderPolicy] = React.useState(false);
    const [preOrderPopupMessageConfirm, setPreOrderPopupMessageConfirm] = React.useState('');

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const copyVoucherPromo = (promo) => {
        navigator.clipboard.writeText(promo);
        window.toastMessage({
            open: true,
            variant: 'success',
            text: 'Voucher Berhasil Disalin',
        });
    };

    const vendorProductDataFlashDiscount = data?.vendor_product_data?.[0]?.flash_sale_percent;
    const vendorProductDataAmount = data?.vendor_product_data?.[0]?.flash_sale_discount_amount;

    React.useEffect(() => {
        if (data && data.vendor_product_data && data.vendor_product_data[0] && data.vendor_product_data[0].preorder > 0) {
            const preOrderDays = getPreorderTime();
            setPreOrderPopupMessageConfirm(t('product:textPreOrderConfirm', { day: preOrderDays }));
        }
    }, [data]);

    return (
        <>
            <div className="hidden-mobile">
                {/* {data && data.upsell_products && data.upsell_products.length > 0 && (
                    <RightDrawer open={openDrawer} setOpen={() => setOpenDrawer(!openDrawer)} {...props} />
                )} */}
                <ModalPopupImage open={openImageDetail} setOpen={handleOpenImageDetail} banner={banner} />
            </div>
            <OptionItem
                {...props}
                open={openOption}
                setOpen={() => setOpenOption(!openOption)}
                setBanner={setBanner}
                setPrice={setPrice}
                isPreOrder={getPreorder}
                preOrderMessage={preOrderPopupMessageConfirm}
            />
            {/* <SharePopup sku={data.sku} open={openShare} setOpen={() => setOpenShare(!openShare)} link={getHost() + route.asPath} {...props} /> */}
            <div className={classNames(styles.container, 'row')}>
                <div className="col-lg-12 hidden-mobile">
                    <Breadcrumb data={breadcrumbsData} variant="text" />
                </div>
                <div className={classNames(styles.headContainer, 'col-xs-12 col-lg-7')}>
                    {desktop ? (
                        <Banner
                            data={banner}
                            noLink
                            thumbnail
                            showArrow={false}
                            contentWidth="auto"
                            autoPlay={false}
                            width={640}
                            height={640}
                            actionImage={handleOpenImageDetail}
                            useDots={false}
                        >
                            <WeltpixelLabel
                                t={t}
                                weltpixel_labels={weltpixel_labels}
                                categoryLabel={false}
                                withThumbnailProduct
                            />
                        </Banner>
                    ) : (
                        <>
                            <div style={{ marginTop: '16%' }} />
                            <BannerMobile
                                data={banner}
                                noLink
                                thumbnail
                                showArrow={false}
                                contentWidth="auto"
                                autoPlay={false}
                                width={420}
                                height={420}
                                actionImage={handleOpenImageDetail}
                            >
                                <WeltpixelLabel
                                    t={t}
                                    weltpixel_labels={weltpixel_labels}
                                    categoryLabel={false}
                                    withThumbnailProduct
                                />
                            </BannerMobile>
                        </>
                    )}
                    <div data-threedy-web-id={data.sku} className={styles.threedyContainer}>
                        <Button className={classNames('hidden hide threedy-3d-btn', styles.btnThreedy)} color="primary">
                            <Typography variant="span" align="center" type="bold" letter="uppercase" color="white" size="10">
                                {t('product:seeIn3D')}
                            </Typography>
                        </Button>
                        <Button className={classNames('hidden hide threedy-qar-btn', styles.btnThreedy)} color="primary">
                            <Typography variant="span" align="center" type="bold" letter="uppercase" color="white" size="10">
                                {t('product:seeInSpace')}
                            </Typography>
                        </Button>
                    </div>
                </div>
                <div className={classNames(styles.body, 'col-xs-12 col-lg-5')}>
                    {isFlashSale ? <FlashSaleTimer View={FlashSaleTimerView} flashSaleData={flashSaleData} /> : null}
                    <div className={styles.titleContainer}>
                        <div className={styles.titlePriceContainer}>
                            <Typography variant="title" type="bold" letter="capitalize" className={classNames(styles.title, 'clear-margin-padding')}>
                                {data.name}
                            </Typography>
                        </div>
                    </div>

                    <div className={styles.titleContainer}>
                        <div className={classNames('row', styles.width100)}>
                            <div className={classNames(styles.noPadding)}>
                                <div className={classNames('row', styles.sku)}>
                                    <Typography className="clear-margin-padding sku-name" variant="p" type="regular" letter="capitalize">
                                        {t('product:sold')}
                                    </Typography>
                                    <Typography variant="p" letter="none">
                                        {generateSoldProduct(data?.product_sold || 0)}
                                    </Typography>
                                </div>
                            </div>
                            <Divider className={styles.divider} orientation="vertical" flexItem />
                            <div className={classNames(styles.noPadding)}>
                                <div className={styles.ratingContainer}>
                                    <div className="rating">
                                        <Star />
                                        <Typography className="rating-count" variant="p" type="regular" letter="capitalize">
                                            {reviewValue || 5}
                                        </Typography>
                                    </div>
                                    <Typography className={styles.reviews} variant="p" type="regular" letter="capitalize">
                                        {'('}
                                        {data.review.reviews_count || 0}
                                        {' '}
                                        {t('product:review')}
                                        {')'}
                                    </Typography>
                                </div>
                            </div>
                            <Divider className={styles.divider} orientation="vertical" flexItem />
                            <div className={classNames(styles.noPadding)}>
                                <div className={classNames('row', styles.sku)}>
                                    <Typography className="clear-margin-padding sku-name" variant="p" type="regular" letter="capitalize">
                                        SKU:
                                        {' '}
                                    </Typography>
                                    <Typography variant="p" letter="none">
                                        {data.sku || ''}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.titleContainer}>
                        <div className={
                            classNames(
                                styles.titlePriceContainer,
                                vendorProductDataFlashDiscount || vendorProductDataAmount ? styles.additionalDiscountContainer : '',
                            )
                        }
                        >
                            <div className={styles.priceContent}>
                                <PriceFormat {...price} />
                            </div>
                            {
                                (vendorProductDataFlashDiscount || vendorProductDataAmount)
                                    && nowTime >= startFlashSaleDate
                                    && nowTime <= endFlashSaleDate
                                    ? (
                                        <>
                                            <div className={styles.additionalDiscountContent}>
                                                <div className={styles.additionalDiscountValue}>
                                                    <span>
                                                        {t('common:additionalDiscount')}
                                                        {' '}
                                                        {formatPrice(flashSaleData.vendor_cost - flashSaleData.flash_sale_price, 'IDR')}
                                                    </span>
                                                </div>
                                            </div>
                                            <Typography
                                                variant="p"
                                                align="left"
                                                letter="capitalize"
                                                className={styles.onlineOnly}
                                            >
                                                (Online Price Only)
                                            </Typography>
                                        </>
                                    ) : null
                            }
                        </div>
                        <div>
                            <IconButton
                                aria-label="yellow ai chat"
                                onClick={handleYellowAiChat}
                                className={styles.chatButton}
                            >
                                <img alt="Message" src="/assets/img/chat-icon-web.png" />
                            </IconButton>
                        </div>
                    </div>

                    {!getPreorder() && (
                        <div className={styles.stockContainer}>
                            <Typography
                                variant="p"
                                align="left"
                                letter="capitalize"
                                className={styles.stockName}
                            >
                                Availability:
                            </Typography>
                            <Typography
                                variant="p"
                                align="left"
                                letter="capitalize"
                                className={getStockVendor() > 0 ? styles.inStock : styles.outOfStock}
                            >
                                {getStockVendor() > 0 ? (
                                    <div className={styles.inStockIcon}>
                                        <CheckCircleIcon className="checkIcon" />
                                        <span>
                                            In Stock
                                        </span>
                                    </div>
                                ) : (
                                    <div className={styles.inStockIcon}>
                                        <img className="checkIcon" alt="unavailable" src="/assets/img/pdp/unavailable.svg" />
                                        <span>
                                            {t('product:outStock')}
                                        </span>
                                    </div>
                                )}
                            </Typography>
                        </div>
                    )}

                    {getPreorder() && (
                        <div className={styles.preOrderContainer}>
                            <div className={styles.stockContainer}>
                                <Typography
                                    variant="p"
                                    align="left"
                                    letter="capitalize"
                                    className={styles.stockName}
                                >
                                    Availability:
                                </Typography>
                                <Typography
                                    variant="p"
                                    align="left"
                                    letter="capitalize"
                                    className={styles.outOfStock}
                                >
                                    <div className={styles.preOrderInfo}>
                                        <span>
                                            {`Pre-Order ${getPreorderTime()} ${t('common:product:day')}`}
                                        </span>
                                    </div>
                                </Typography>
                            </div>
                            <button
                                type="button"
                                className={styles.preorderPolicyButton}
                                onClick={() => setOpenPreorderPolicy(true)}
                            >
                                <Typography>
                                    {t('product:preOrderPolicyButton')}
                                </Typography>
                            </button>
                            <Dialog
                                open={openPreorderPolicy}
                                onClose={() => setOpenPreorderPolicy(false)}
                                className={styles.modalPreOrderPolicy}
                            >
                                <div className={styles.headerPreOrderPolicy}>
                                    <Typography variant="h4" size="20" className={styles.titlePreOrderPolicy} letter="none">
                                        { t('product:titlePreOrderPopup')}
                                    </Typography>
                                    <IconButton className={styles.buttonClose} onClick={() => setOpenPreorderPolicy(false)}>
                                        <Close color="inherit" fontSize="inherit" />
                                    </IconButton>
                                </div>
                                <div className={styles.preOrderTextContainer}>
                                    <ul className={styles.preOrderListContainer}>
                                        <li>
                                            <Typography variant="p" size="14" className="clear-margin-padding" letter="none">
                                                {t('product:preOrderFirst')}
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant="p" size="14" className="clear-margin-padding" letter="none">
                                                {t('product:preOrder')}
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant="p" size="14" className="clear-margin-padding" letter="none">
                                                {t('product:preOrderThird')}
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant="p" size="14" className="clear-margin-padding" letter="none">
                                                {t('product:preOrderFourth')}
                                            </Typography>
                                        </li>
                                    </ul>
                                </div>
                            </Dialog>
                        </div>
                    )}

                    {data?.sku_sensitive && (
                        <div className={styles.preOrderTextContainer}>
                            <img className="preorder" alt="unavailable" src="/assets/img/pdp/warning.svg" />
                            <Typography variant="p" size="14" className="clear-margin-padding" letter="none">
                                {t('product:noPromo')}
                            </Typography>
                        </div>
                    )}

                    {data.product_voucher_code ? (
                        <div className={styles.stockContainer}>
                            <Typography
                                variant="p"
                                align="left"
                                letter="capitalize"
                                className={styles.stockName}
                            >
                                Kode Promo:
                            </Typography>
                            <Typography
                                variant="p"
                                type="bold"
                                onClick={() => copyVoucherPromo(data.product_voucher_code)}
                                className={classNames(styles.outOfStock, 'clear-margin-padding')}
                            >
                                <div className={styles.inStockIcon}>
                                    <ConfirmationNumberIcon className={styles.voucherIcon} />
                                    <span className="voucher">
                                        {data.product_voucher_code}
                                    </span>
                                </div>
                            </Typography>
                        </div>
                    ) : null}

                    <div className={styles.titleContainer}>
                        <XenditCalculator t={t} product={data} price={price} isLogin={isLogin} />
                    </div>

                    <div className={styles.titleContainer}>
                        <IndodanaCalculator t={t} product={data} price={price} isLogin={isLogin} />
                    </div>

                    <ShippingMethodAndStore {...props} t={t} product={data} getAvailableSource={getAvailableSource} />
                    <div>
                        <div className="hidden-mobile">
                            <DesktopOptions
                                {...props}
                                setOpen={setOpenOption}
                                setBanner={setBanner}
                                setPrice={setPrice}
                                getStockVendor={getStockVendor}
                                isPreOrder={getPreorder}
                                preOrderMessage={preOrderPopupMessageConfirm}
                            />
                        </div>
                        <div className={classNames(styles.desc)}>
                            {
                                data?.short_description?.html ? (
                                    <>
                                        <div className={openDetailDesc ? styles.fullDesc : styles.shortDescDesktop}>
                                            <div dangerouslySetInnerHTML={{ __html: data.short_description.html }} />
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                            }}
                                        >
                                            <button
                                                type="button"
                                                className={styles.detailShortDescBtn}
                                                onClick={() => setOpenDetailDesc(!openDetailDesc)}
                                            >
                                                <Typography>
                                                    {
                                                        openDetailDesc ? '- Show Less' : '+ Show More'
                                                    }
                                                </Typography>
                                            </button>
                                        </div>
                                    </>
                                ) : null
                            }
                        </div>
                        <div className={styles.shareContainerDesktop}>
                            <div className={styles.socialShareDesktop}>
                                <div className="desc">
                                    {t('product:shareTitle')}
                                    :
                                </div>
                                {/* <IconButton className={classNames(styles.btnShareDesktop)} onClick={handleOpenModal}>
                                    <img alt="wishlist" src="/assets/img/pdp/new_insta.svg" />
                                </IconButton> */}
                                <FacebookShareButton
                                    onClick={handleClickStreamShare}
                                    className={classNames(styles.btnShareDesktop)}
                                    url={getHost() + route.asPath}
                                >
                                    <img alt="wishlist" src="/assets/img/pdp/new_facebook.svg" />
                                </FacebookShareButton>

                                <TwitterShareButton
                                    onClick={handleClickStreamShare}
                                    className={classNames(styles.btnShareDesktop)}
                                    url={getHost() + route.asPath}
                                >
                                    <img alt="wishlist" src="/assets/img/pdp/new_x.svg" />
                                </TwitterShareButton>
                                <WhatsappShareButton
                                    onClick={handleClickStreamShare}
                                    className={classNames(styles.btnShareDesktop)}
                                    url={getHost() + route.asPath}
                                >
                                    <img alt="wishlist" src="/assets/img/pdp/new_wa.svg" />
                                </WhatsappShareButton>
                            </div>
                            <div>
                                <IconButton className={styles.btnShareDesktop} onClick={handleWishlist}>
                                    {/* {favoritIcon} */}
                                    <img alt="wishlist" src="/assets/img/pdp/new_wish.svg" />
                                    <Typography className={styles.shareText}>
                                        Save
                                    </Typography>
                                </IconButton>
                                <IconButton className={styles.btnShareDesktop} onClick={handleCompare}>
                                    <img alt="compare" src="/assets/img/pdp/new_share.svg" />
                                    <Typography className={styles.shareText}>
                                        Compare
                                    </Typography>
                                </IconButton>
                            </div>
                        </div>
                        {
                            data?.layanan_instalasi ? (
                                <div className={styles.installationWrapper}>
                                    <div className={styles.installationContent}>
                                        <img alt="installation" src="/assets/img/pdp/installation.png" />
                                        <div>
                                            <Typography type="bold" variant="p" className={styles.installationTitle}>
                                                {t('product:installationService')}
                                            </Typography>
                                            <Typography className={styles.installationDesc}>
                                                {t('product:installation')}
                                            </Typography>
                                            <Link href="/layanan-instalasi">
                                                <a className={styles.installationLink}>
                                                    {t('product:installationLink')}
                                                </a>
                                            </Link>
                                        </div>
                                    </div>
                                    <Link href="/layanan-instalasi">
                                        <a>
                                            <ChevronRightIcon className="iconRight" />
                                        </a>
                                    </Link>
                                </div>
                            ) : null
                        }
                    </div>

                    <div className="hidden-desktop">
                        {/* <div className={classNames(styles.desc)}>
                            <Typography variant="span" type="regular" size="12">
                                {data.short_description.html ? <span dangerouslySetInnerHTML={{ __html: data.short_description.html }} /> : null}
                            </Typography>
                            <div className={classNames(styles.iframeWrapper)}>
                                {
                                    data.link_video ? (
                                        <iframe
                                            src={data.link_video}
                                            title="YouTube video player"
                                            allowFullScreen
                                        />
                                    ) : null
                                }
                            </div>
                        </div> */}
                        <div>
                            <ExpandDetail data={expandData} sku={data.sku} />
                        </div>
                    </div>
                </div>
                <div className="hidden-desktop">
                    <ListReviewsMobile {...props} />
                </div>
                <div className={classNames(styles.tabs, 'col-xs-12 col-lg-12 hidden-mobile')}>
                    <TabsView {...props} dataInfo={expandData} />
                </div>
                {relateData.length !== 0 ? (
                    <div className={classNames(styles.carouselContainer, 'col-xs-12 col-lg-12')}>
                        <Typography variant="h1" component="h2" align="center" className={styles.carouselTitle}>
                            {t('common:title:relatedProduct')}
                        </Typography>
                        {contentCaraousel}
                    </div>
                ) : null}

                <div className={classNames('col-xs-12 col-lg-12')}>
                    <ViewAgainProducts t={t} vendor={props.vendor} storeConfig={storeConfig} isLogin={isLogin} />
                </div>

                <div className={classNames(styles.footer, 'hidden-desktop')}>
                    {(getStockVendor() > 0 || getPreorder()) && (price?.vendorCost !== 0 || price?.vendorSpecialPrice !== 0) ? (
                        <Button className={styles.btnAddToCard} color="primary" onClick={handleOption}>
                            <Typography variant="span" align="center" type="bold" letter="uppercase" color="white">
                                {t('product:addToCart')}
                            </Typography>
                        </Button>
                    ) : (
                        <Typography align="center" type="semiBold" color="red" variant="subtitle2">
                            {t('product:productIsUnavailable')}
                        </Typography>
                    )}
                </div>
            </div>
            <script
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: 'var QUICK_AR_INIT = {key : "4LytRhdbKD"}',
                }}
            />
            <script src="https://cdn-quick-ar.threedy.ai/latest/threedy.js" defer />
        </>
    );
};

export default ProductPage;
