/* eslint-disable no-empty */
/* eslint-disable array-callback-return */
/* eslint-disable guard-for-in */
import React from 'react';
import PropTypes from 'prop-types';
import Router, { useRouter } from 'next/router';
import getQueryFromPath from '@helper_generatequery';
import TagManager from 'react-gtm-module';
import { modules } from '@config';
import { getProduct, getProductAgragations } from '@modules/catalog/services/graphql';
import * as Schema from '@modules/catalog/services/graphql/productSchema';
import getCategoryFromAgregations from '@core_modules/catalog/helpers/getCategory';
import generateConfig from '@modules/catalog/helpers/generateConfig';
import Content from './components';

const Product = (props) => {
    const {
        catId = 0, catalog_search_engine, customFilter, url_path, defaultSort, t, isBrandDetailPage, brandPath, brandName,
        categoryPath, ErrorMessage, storeConfig, query, path, availableFilter, vendorId,
        isCustomSearch, searchKeyword, ...other
    } = props;

    const router = useRouter();
    const [page, setPage] = React.useState(1);
    const [pageParam, setPageParam] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(modules.catalog.productListing.pageSize || 10);
    const [loadmore, setLoadmore] = React.useState(false);
    const [firstLoad, setFistLoad] = React.useState(true);
    const elastic = catalog_search_engine === 'elasticsuite';
    let config = {
        customFilter: false,
        search: '',
        pageSize: 8,
        currentPage: 1,
        filter: [],
        vendorId,
    };

    // set default sort when there is no sort in query
    if (defaultSort && !query.sort) {
        query.sort = JSON.stringify(defaultSort);
    }

    React.useEffect(() => {
        if (firstLoad) {
            const activePageParam = new URL(window.location.href).searchParams.get('page');
            const activePageParamPageSize = new URL(window.location.href).searchParams.get('pagesize');

            if (activePageParamPageSize) {
                setPageSize(Number(activePageParamPageSize));
            } else {
                setPageSize(Number(modules.catalog.productListing.pageSize));
            }

            if (activePageParam) {
                setPageParam(Number(activePageParam));
            } else {
                setPageParam(1);
            }

            document.querySelector('html').style.scrollBehavior = 'smooth';
            setFistLoad(false);
        }
    }, [pageParam, firstLoad]);

    const setFiltervalue = (v) => {
        let queryParams = '';
        // eslint-disable-next-line array-callback-return
        Object.keys(v).map((key) => {
            if (key === 'selectedFilter') {
                // eslint-disable-next-line no-restricted-syntax
                for (const idx in v.selectedFilter) {
                    if (idx !== brandPath) {
                        if (v.selectedFilter[idx] !== '' && !v[idx] && idx !== 'page' && idx !== 'pagesize') {
                            queryParams += `${queryParams !== '' ? '&' : ''}${idx}=${v.selectedFilter[idx]}`;
                        }
                    }
                }
            } else if (v[key] !== 0 && v[key] !== '') {
                queryParams += `${queryParams !== '' ? '&' : ''}${key}=${v[key]}`;
            }
        });

        if (isBrandDetailPage) {
            Router.push(`/${encodeURI(`${brandPath}${queryParams ? `?${queryParams}` : ''}`)}`);
        } else {
            Router.push(`/${url_path || '[...slug]'}`, encodeURI(`${path}${queryParams ? `?${queryParams}` : ''}`));
        }
        setPageParam(1);
    };
    if (catId !== 0) {
        config.filter.push({
            type: 'category_id',
            value: catId,
        });
    }

    if (isBrandDetailPage) {
        if (isCustomSearch) {
            config.search = searchKeyword;
        } else {
            config.filter.push({
                type: 'brand',
                value: brandName.toUpperCase().replace(/-/g, ' '),
            });
        }
    }

    config = generateConfig(query, config, elastic, availableFilter);
    const { loading, data, fetchMore } = getProduct(config, {
        variables: {
            // pageSize: modules.catalog.productListing.pageSize || 10,
            pageSize,
            currentPage: pageParam,
        },
    });
    let products = {};
    products = data && data.products ? data.products : {
        total_count: 0,
        items: [],
    };
    // generate filter if donthave custom filter
    const aggregations = [];
    if (!customFilter && !loading && products.aggregations) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < products.aggregations.length; index++) {
            aggregations.push({
                field: products.aggregations[index].attribute_code,
                label: products.aggregations[index].label,
                value: products.aggregations[index].options,
            });
        }
    }
    const category = getCategoryFromAgregations(aggregations);

    // eslint-disable-next-line no-shadow
    const renderEmptyMessage = (count, loading) => {
        if (count || loading) {
            return null;
        }
        return <ErrorMessage variant="warning" text={t('catalog:emptyProductSearchResult')} open />;
    };

    const handlePageParams = (pageValue) => {
        const url = new URL(document.URL);
        const params = new URLSearchParams(url.search);
        let urlPath;

        params.delete('page');

        if (params.size) {
            if (isBrandDetailPage) {
                urlPath = decodeURIComponent(`${brandPath}${params ? `?${params}` : ''}`);
                router.push(`/${urlPath}&page=${pageValue}`);
            } else {
                urlPath = decodeURIComponent(`${path}${params ? `?${params}` : ''}`);
                router.push(`${urlPath}&page=${pageValue}`);
            }
        } else {
            urlPath = `${path}`;
            if (isBrandDetailPage) {
                Router.push(`/${encodeURI(`${brandPath}?page=${pageValue}`)}`);
            } else {
                router.push(`${urlPath}?page=${pageValue}`);
            }
        }
        setPageParam(Number(pageValue));
    };

    const handlePageSize = (e) => {
        const selectedPageSize = e.target.value;
        const url = new URL(document.URL);
        const params = new URLSearchParams(url.search);
        let urlPath;

        params.delete('pagesize');
        params.delete('page');

        if (params.size) {
            if (isBrandDetailPage) {
                urlPath = decodeURIComponent(`${brandPath}${params ? `?${params}` : ''}`);
                router.push(`/${urlPath}&page=${1}&pagesize=${selectedPageSize}`);
            } else {
                urlPath = decodeURIComponent(`${path}${params ? `?${params}` : ''}`);
                router.push(`${urlPath}&page=${1}&pagesize=${selectedPageSize}`);
            }
        } else {
            urlPath = `${path}`;
            if (isBrandDetailPage) {
                Router.push(`/${encodeURI(`${brandPath}?page=${1}&pagesize=${selectedPageSize}`)}`);
            } else {
                router.push(`${urlPath}?page=${1}&pagesize=${selectedPageSize}`);
            }
        }
        document.querySelector('html').style.scrollBehavior = 'smooth';
        setPageParam(1);
        setPageSize(selectedPageSize);
    };

    const handleLoadMore = async (e, value) => {
        // const pageSize = modules.catalog.productListing.pageSize || 10;
        // setFilterSaved(false);
        setLoadmore(true);
        try {
            const totalProduct = products && products.total_count ? products.total_count : 0;
            const totalPage = Math.ceil(totalProduct / pageSize);
            if (fetchMore && typeof fetchMore !== 'undefined' && value <= totalPage) {
                setPage(page + 1);
                fetchMore({
                    query: Schema.getProduct({ ...config, currentPage: Number(value) }),
                    variables: {
                        pageSize,
                        currentPage: Number(value),
                    },
                    updateQuery: (
                        previousResult,
                        { fetchMoreResult },
                    ) => {
                        setLoadmore(false);
                        return {
                            products: {
                                ...fetchMoreResult.products,
                                items: [
                                    // ...previousResult.products.items,
                                    ...fetchMoreResult.products.items,
                                ],
                            },
                        };
                    },
                });

                handlePageParams(value);
            }
        } catch (error) { }
    };

    React.useEffect(() => {
        if (data && data.products) {
            const tagManagerArgs = {
                dataLayer: {
                    event: 'impression',
                    eventCategory: 'Ecommerce',
                    eventAction: 'Impression',
                    eventLabel: categoryPath ? `category ${categoryPath}` : '',
                    ecommerce: {
                        currencyCode: storeConfig && storeConfig.base_currency_code ? storeConfig.base_currency_code : 'IDR',
                        impressions: data.products.items.map((product, index) => {
                            let categoryProduct = '';
                            // eslint-disable-next-line no-unused-expressions
                            product.categories.length > 0 && product.categories.map(({ name }, indx) => {
                                if (indx > 0) categoryProduct += `/${name}`;
                                else categoryProduct += name;
                            });
                            return {
                                name: product.name,
                                id: product.sku,
                                category: categoryProduct,
                                price: product.price_range.minimum_price.regular_price.value,
                                list: categoryProduct,
                                position: index,
                            };
                        }),
                    },
                },
            };

            // GA 4 dataLayer
            const tagManagerArgsGA4 = {
                dataLayer: {
                    event: 'view_item_list',
                    pageName: categoryPath,
                    pageType: 'category',
                    ecommerce: {
                        items: data.products.items.map((product, index) => {
                            let categoryProduct = '';
                            let categoryOne = '';
                            let categoryTwo = '';
                            // eslint-disable-next-line no-unused-expressions
                            product.categories.length > 0 && (
                                categoryOne = product.categories[0].name,
                                categoryTwo = product.categories[1]?.name,
                                product.categories.map(({ name }, indx) => {
                                    if (indx > 0) categoryProduct += `/${name}`;
                                    else categoryProduct += name;
                                })
                            );
                            return {
                                item_name: product.name,
                                item_id: product.sku,
                                price: product.price_range.minimum_price.regular_price.value,
                                item_category: categoryOne,
                                item_category_2: categoryTwo,
                                item_list_name: categoryProduct,
                                index,
                                currency: product.price_range.minimum_price.regular_price.currency,
                            };
                        }),
                    },
                },
            };

            TagManager.dataLayer(tagManagerArgs);
            TagManager.dataLayer(tagManagerArgsGA4);
        }
    }, [data]);

    const contentProps = {
        loadmore,
        loading,
        t,
        query,
        customFilter,
        elastic,
        aggregations,
        setFiltervalue,
        category,
        defaultSort,
        config,
        products,
        categoryPath,
        handleLoadMore,
        renderEmptyMessage,
        storeConfig,
        isBrandDetailPage: isBrandDetailPage && !isCustomSearch,
        pageSize,
        handlePageSize,
    };

    return (
        <Content
            {...contentProps}
            {...other}
        />
    );
};

Product.propTypes = {
    // eslint-disable-next-line react/require-default-props
    catId: PropTypes.number,
    // eslint-disable-next-line react/require-default-props
    catalog_search_engine: PropTypes.string,
};

const ProductWrapper = (props) => {
    const {
        isBrandDetailPage = false,
        brandPath = '',
    } = props;
    const router = useRouter();
    const { path, query } = getQueryFromPath(router, isBrandDetailPage, brandPath);

    let availableFilter = [];
    let loadingAgg;
    if (Object.keys(query).length > 0) {
        const { data: agg, loading } = getProductAgragations();
        loadingAgg = loading;
        availableFilter = agg && agg.products ? agg.products.aggregations : [];
    }
    if (loadingAgg) {
        return <span />;
    }
    return (
        <Product
            {...props}
            availableFilter={availableFilter}
            path={path}
            query={query}
        />
    );
};

export default ProductWrapper;
