/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import Thumbor from '@common_imageThumbor';
import Link from 'next/link';
import React from 'react';
import { features } from '@config';
import classNames from 'classnames';
import useStyles from '../style';

const ImageDetail = (props) => {
    const {
        handleClick, small_image, spesificProduct, name, url_key,
        isRecentlyViewed = false,
    } = props;
    const styles = useStyles();
    const ctaClass = isRecentlyViewed ? 'gtm_mitra10_cta_recently_product' : 'gtm_mitra10_cta_product';
    return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link href="/[...slug]" as={`/${url_key}`} style={{ width: '100%' }}>
            <a className={classNames(styles.linkProduct, ctaClass)} onClick={handleClick}>
                <Thumbor
                    className={styles.imgProduct}
                    src={spesificProduct.id ? spesificProduct.image.url
                        : small_image && small_image.url_original
                            ? small_image.url_original
                            : '/assets/img/placeholder.png'}
                    alt={small_image && small_image.url ? small_image.label : name}
                    width={features.imageSize.product.width}
                    height={features.imageSize.product.height}
                />
            </a>
        </Link>
    );
};

export default ImageDetail;
