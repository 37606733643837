/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Button from '@material-ui/core/IconButton';
import PriceFormat from '@common_priceformat';
// import RatingStar from '@common_ratingstar';
import Typography from '@common_typography';
import { modules } from '@config';
import Link from 'next/link';
import React from 'react';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorderOutlined from '@material-ui/icons/FavoriteBorderOutlined';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import classNames from 'classnames';
import Star from '@material-ui/icons/Star';
import { useTranslation } from '@i18n';
import generateSoldProduct from '@root/src/helpers/generateSoldProduct';
import Divider from '@material-ui/core/Divider';
import dynamic from 'next/dynamic';
import useStyles from '../style';

const AdditionalDiscountLabel = dynamic(() => import('@modules/home/pages/default/components/AdditionalDiscount'), { ssr: false });

const Detail = (props) => {
    const {
        spesificProduct, handleClick, name, handleFeed, handleCompare, ratingValue, __typename, price_range, price_tiers,
        feed, special_from_date, special_to_date, vendor_product_data, url_key, product_sold = 0,
        isRecentlyViewed = false,
    } = props;
    const styles = useStyles();
    const { t } = useTranslation(['product']);
    const classFeedActive = classNames(styles.iconFeed, styles.iconActive);
    const FeedIcon = feed ? <Favorite className={classFeedActive} /> : <FavoriteBorderOutlined className={styles.icon} />;
    return (
        <div
            className={styles.descItem}
            style={{ ...(modules.wishlist.enabled ? {} : { alignItems: 'center' }), height: 95 }}
        >
            <PriceFormat
                // eslint-disable-next-line camelcase
                priceRange={spesificProduct.price_range ? spesificProduct.price_range : price_range}
                // eslint-disable-next-line camelcase
                priceTiers={spesificProduct.price_tiers ? spesificProduct.price_tiers : price_tiers}
                productType={__typename}
                specialFromDate={special_from_date}
                specialToDate={special_to_date}
                vendorCost={vendor_product_data[0].vendor_cost}
                vendorSpecialPrice={vendor_product_data[0].vendor_special_price}
                flashSalePrice={vendor_product_data[0].flash_sale_price}
                flashSaleFrom={vendor_product_data[0].flash_sale_from}
                flashSaleTo={vendor_product_data[0].flash_sale_to}
                vendorFlashSalePercent={vendor_product_data[0].flash_sale_percent}
                vendorFlashSaleDiscountAmount={vendor_product_data[0].flash_sale_discount_amount}
            />
            <AdditionalDiscountLabel {...props} />
            {modules.wishlist.enabled && (
                <Button
                    className={styles.btnFeed}
                    onClick={() => handleFeed(props)}
                >
                    {FeedIcon}
                </Button>
            )}
            <Button
                className={styles.btnCompare}
                onClick={handleCompare}
            >
                <CompareArrowsIcon className={styles.icon} />
            </Button>
            <Link href="/[...slug]" as={`/${url_key}`} className={styles.productLinkButton}>
                <a
                    className={isRecentlyViewed ? 'gtm_mitra10_cta_recently_product' : 'gtm_mitra10_cta_product'}
                    onClick={() => handleClick(props)}
                >
                    <Typography
                        variant="p"
                        className={styles.productTitle}
                        letter="capitalize"
                    >
                        {name}
                    </Typography>
                </a>
            </Link>
            {/* {modules.catalog.productListing.rating && <RatingStar value={ratingValue} />} */}
            {modules.catalog.productListing.rating ? (
                <div className={styles.ratingContainer}>
                    <div className="rating">
                        <Star />
                        <Typography className="rating-count" variant="p" type="regular" letter="capitalize">
                            {ratingValue || 5}
                        </Typography>
                    </div>
                    <Divider className={styles.divider} orientation="vertical" flexItem />
                    <div className="sold-container">
                        <Typography size={12} className="clear-margin-padding" variant="p" letter="capitalize">
                            {generateSoldProduct(product_sold)}
                            {' '}
                            {t('product:soldPlp')}
                        </Typography>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default Detail;
