/* eslint-disable no-dupe-keys */
import { makeStyles } from '@material-ui/core/styles';
import { ERROR, GRAY_400 } from '@src_theme/colors';

const useStyles = makeStyles(() => ({
    topMobile: {
        display: 'none',
        flexDirection: 'row',
        width: '100%',
        backgroundColor: 'transparent',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 10,
    },
    root: {
        display: 'flex',
        backgroundColor: 'transparent',
    },
    title: {
        height: '100%',
        fontWeight: 'bold',
        lineHeight: '8vw',
        color: '#fff',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '20%',
        fontSize: '2.5vw',
        paddingLeft: 16,
        '@media (min-width: 768px )': {
            lineHeight: 2.5,
            fontSize: 16,
            marginRight: 20,
        },
        '@media (min-width: 850px )': {
            fontSize: 18,
        },
        '@media (min-width: 1200px )': {
            marginRight: 20,
            paddingLeft: 24,
            letterSpacing: 2,
        },
    },
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '100%',
        flexBasis: '100%',
        '@media (max-width: 767px )': {
            justifyContent: 'flex-start',
        },
        '@media (max-width: 668px )': {
            justifyContent: 'flex-start',
            padding: 0,
        },
    },
    endFlex: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '10px',
        '@media (max-width: 668px )': {
            fontSize: '14px',
            marginBottom: 0,
        },
    },
    endsIn: {
        '@media (min-width: 768px )': {
            fontSize: 12,
        },
        '@media (max-width: 767px )': {
            textShadow: '2px 2px #00000029',
        },
        verticalAlign: 'middle',
        color: '#000',
    },
    endsInFlash: {
        fontWeight: 'bold',
    },
    viewAll: {
        '@media (min-width: 768px )': {
            fontSize: 14,
        },
        '@media (max-width: 768px )': {
            fontSize: '12px !important',
        },
        '@media (max-width: 668px )': {
            fontSize: '12px !important',
        },
        verticalAlign: 'middle',
        color: '#2E7BBF',
        marginRight: 5,
        fontWeight: 'bold',
    },
    spacer: {
        width: 32,
        color: GRAY_400,
        textAlign: 'center',
    },
    timeContainer: {
        borderRadius: 7,
        backgroundColor: ERROR,
        width: 32,
        height: 32,
        borderRadius: 4,
        padding: '3px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        fontSize: 14,
        fontWeight: 600,
    },
    timeDesc: {
        fontSize: 8,
        marginTop: -5,
        textTransform: 'uppercase',
    },
    flashBox: {
        borderRadius: '8px',
        marginBottom: '15px',
        padding: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '@media (min-width: 320px )': {
            '& h1': {
                fontSize: 14,
                color: 'white',
            },
        },
        '@media (min-width: 768px )': {
            marginTop: 25,
            '& h1': {
                fontSize: 16,
            },
        },
        '@media (min-width: 1440px )': {
            '& h1': {
                fontSize: 18,
            },
        },
    },
    timerFlash: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginBottom: '20px',
    },
    timeWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        '@media (max-width: 768px )': {
            '& .pdpTime': {
                padding: '4px 0',
                marginLeft: '4vw',
                '&:not(:last-child):after': {
                    content: '":"',
                    display: 'block',
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: '-45%',
                    fontSize: '30px',
                },
            },
        },
        '@media (max-width: 568px )': {
            '& .pdpTime': {
                '&:not(:last-child):after': {
                    right: '-35%',
                },
            },
        },
        '@media (min-width: 768px )': {
            '& .pdpTime': {
                padding: '4px 0',
                marginLeft: '1.5vw',
                '&:not(:last-child):after': {
                    content: '":"',
                    display: 'block',
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: '-30%',
                    fontSize: '30px',
                },
            },
        },
        '@media (min-width: 1200px )': {
            '& .pdpTime': {
                padding: '4px 0',
                marginLeft: '1vw',
                '&:not(:last-child):after': {
                    content: '":"',
                    display: 'block',
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: '-30%',
                    fontSize: '30px',
                },
            },
        },
    },
    timeCenter: {
        textAlign: 'center',
    },
    titleFlash: {
        margin: 0,
    },
    flashTitleWrapper: {
        borderRadius: 7,
        // border: '1px solid #FF1726',
        // backgroundColor: '#FF1726',
        alignItems: 'center',
        height: 'auto',
        // padding: '8px 10px',
        // boxShadow: '0px 3px 6px #00000029',
        position: 'relative',
        color: '#ffffff',
        display: 'flex',
        '@media (max-width: 768px )': {
            fontSize: 14,
            padding: '6px 10px',
        },
    },
    flashSaleText: {
        marginLeft: '5px',
        fontSize: '18px',
        fontWeight: 'bold',
        '@media (max-width: 768px )': {
            fontSize: 14,
        },
    },
    timerFlashPdp: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '16px',
        background: '#D72C0D',
        borderRadius: '5px',
        padding: '0px 12px',
        '& .pdpTime': {
            padding: 0,
            width: 'auto',
            marginLeft: '6px',
            fontSize: '18px',
            fontWeight: 'bold',
            '@media (max-width: 768px )': {
                fontSize: 14,
            },
        },
    },
    timeWrapperPdp: {
        display: 'flex',
    },
}));

export default useStyles;
