import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import propTypes from 'prop-types';
import { useTranslation } from '@i18n';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './style';

const PreOrderConfirmDialog = ({
    open = false, handleYes, handleCancel, message,
}) => {
    const { t } = useTranslation(['common']);
    const styles = useStyles();
    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={styles.dialogWrapper}
        >
            <DialogTitle id="stores-dialog-title" className={styles.dialogTitle}>
                <span>
                    {t('product:titlePreOrderPopupConfirm')}
                </span>
                <CloseIcon
                    onClick={handleCancel}
                />
            </DialogTitle>
            <DialogContent
                className={styles.dialogContent}
            >
                <div className="dialogContent">
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </div>
            </DialogContent>
            <DialogActions
                className={styles.dialogAction}
            >
                <Button className={styles.actionCancel} onClick={handleCancel} variant="contained">
                    {t('common:button:cancel')}
                </Button>
                <Button className={styles.actionConfirm} onClick={handleYes} variant="contained" color="primary" autoFocus>
                    {t('common:button:yes')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

PreOrderConfirmDialog.propTypes = {
    open: propTypes.bool.isRequired,
    handleYes: propTypes.func.isRequired,
    handleCancel: propTypes.func.isRequired,
    message: propTypes.string,
};

PreOrderConfirmDialog.defaultProps = {
    message: 'Are you Sure ?',
};

export default PreOrderConfirmDialog;
