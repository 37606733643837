import makeStyles from '@material-ui/core/styles/makeStyles';
import { GRAY_SECONDARY } from '@theme_color';
import { FlexRow, CreatePadding } from '@theme_mixins';
import { FONT_10 } from '@theme_typography';

const useStyles = makeStyles(() => ({
    containerWrap: {
        '& .MuiFormControlLabel-root': {
            marginRight: 0,
            marginBottom: 0,
            fontSize: 8,
        },
        '& .MuiTypography-body1': {
            fontSize: 12,
            fontWeight: 400,
        },
    },
    filterContainer: {
        ...FlexRow,
        justifyContent: 'space-between',
        alignItems: 'center',
        ...CreatePadding(15, 15, 15, 15),
    },
    countProductText: {
        justifyContent: 'flex-start',
        ...FONT_10,
    },
    filterBtnContainer: {
        justifyContent: 'flex-end',
        ...FlexRow,
        alignItems: 'center',
        fontSize: 12,
    },
    btnFilter: {
        marginRight: -20,
        padding: 0,
    },
    iconFilter: {
        fontSize: 18,
        fontWeight: 'reguler',
    },
    tabs: {
        boxShadow: 'none',
        borderBottom: `1px solid ${GRAY_SECONDARY}`,
    },
    productContainer: {
        overflow: 'hidden',
        ...CreatePadding(0, 0, 20, 0),
    },
    btnLoadmore: {
        cursor: 'pointer',
        width: '100%',
        padding: '20px',
        fontSize: '12px',
        background: '#fff',
        border: 'none',
        color: '#B4B4B4',
    },
    countProductTextDesktop: {
        justifyContent: 'flex-start',
        fontSize: 12,
        marginTop: -25,
    },
    divLoadMore: {
        width: '100%',
        textAlign: 'center',
        padding: 20,
    },
    paginationStyle: {
        display: 'flex',
        justifyContent: 'center',
        padding: '16px',
    },
    sliderWrapper: {
        marginTop: '16px',
        marginBottom: '16px',
    },
    topSellerTitle: {
        fontWeight: 'bold !important',
        fontSize: '14px !important',
    },
    paginationWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (max-width: 767px )': {
            flexDirection: 'column',
        },
    },
}));

export default useStyles;
