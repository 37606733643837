/* eslint-disable react/no-danger */
import React from 'react';
import { getCmsBlocks } from '@modules/theme/services/graphql';
import useStyles from './style';

const CmsBlockWidget = (props) => {
    const {
        block_id,
    } = props;
    const styles = useStyles();
    const { data, loading, error } = getCmsBlocks({ identifiers: [block_id] });

    if (loading || error) {
        return null;
    }

    const content = data
        && data.cmsBlocks
        && data.cmsBlocks.items
        && data.cmsBlocks.items[0]
        && data.cmsBlocks.items[0].content;

    if (content) {
        const validFrom = data.cmsBlocks.items[0].valid_from;
        const validTo = data.cmsBlocks.items[0].valid_to;
        const isValidNow = Date.now() > new Date(validFrom).getTime()
            && Date.now() < new Date(validTo).getTime();

        if (isValidNow) {
            return (
                <div className={styles.container} dangerouslySetInnerHTML={{ __html: content }} />
            );
        }
        return null;
    }
    return null;
};

export default CmsBlockWidget;
