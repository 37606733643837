import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {
    createContext, useContext, useRef, useState,
} from 'react';
import { useTranslation } from '@i18n';
import { withStyles } from '@material-ui/core/styles';

export const InstallationServiceDialogContext = createContext({
    open: false,
    setOpen: () => {},
    onSubmit: () => {},
    isInstallationService: false,
    installationServiceOption: { id: null, uid: null },
    optionsValue: '',
    setOptionsValue: () => {},
});

export function useInstallationServiceDialog() {
    const context = useContext(InstallationServiceDialogContext);

    if (!context) {
        throw new Error('useInstallationServiceDialog must be used within an InstallationServiceDialog.Provider');
    }

    return {
        open({ onSubmit }) {
            context.setOpen(true);
            if (onSubmit) context.onSubmit.current = onSubmit;
        },
        close() {
            context.setOpen(false);
        },
        isInstallationService: context.isInstallationService,
        installationServiceOption: context.installationServiceOption,
        optionsValue: context.optionsValue,
        setOptionsValue: context.setOptionsValue,
    };
}

const StyledDialog = withStyles({
    paper: { minWidth: 400 },
})(Dialog);

const StyledDialogTitle = withStyles({
    root: {
        paddingTop: '1.5rem',
        paddingBottom: '0.5rem',
        '& > .MuiTypography-root': {
            fontSize: '1rem',
            fontWeight: 'bold',
            textAlign: 'center',
        },
        '& .MuiIconButton-root': {
            position: 'absolute',
            right: '0.5rem',
            top: '0.3rem',
        },
    },
})(DialogTitle);

const StyledDialogContent = withStyles({
    root: {
        paddingTop: 0,
        paddingBottom: 0,
    },
})(DialogContent);

const StyledDialogActions = withStyles({
    root: {
        justifyContent: 'space-evenly',
        padding: '16px 24px',
        '& > .MuiButton-root': {
            flexBasis: '50%',
            borderRadius: 5,
            fontWeight: 'bold',
        },
        gap: '1rem',
    },
})(DialogActions);

const InstallationServiceDialog = () => {
    const { t } = useTranslation(['product', 'common']);
    const {
        open,
        setOpen,
        onSubmit,
        optionsValue,
        setOptionsValue,
    } = useContext(InstallationServiceDialogContext);
    // const [value, setValue] = useState('');

    function handleClose() {
        setOpen(false);
    }

    return (
        <StyledDialog open={open} onClose={handleClose} aria-labelledby="installation-service-dialog-title">
            <StyledDialogTitle id="installation-service-dialog-title" disableTypography>
                <Typography variant="h3">{t('product:form:typeOfService')}</Typography>
                <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </StyledDialogTitle>
            <StyledDialogContent>
                <TextField
                    variant="outlined"
                    autoFocus
                    margin="dense"
                    id="name"
                    fullWidth
                    placeholder={t('product:form:installationServicePlaceholder')}
                    value={optionsValue}
                    onInput={(e) => setOptionsValue(e.target.value)}
                    required
                />
            </StyledDialogContent>
            <StyledDialogActions>
                <Button onClick={handleClose} color="primary" variant="outlined">
                    {t('common:button:cancel')}
                </Button>
                <Button onClick={() => onSubmit.current(optionsValue)} color="primary" variant="contained">
                    {t('common:button:add')}
                </Button>
            </StyledDialogActions>
        </StyledDialog>
    );
};

InstallationServiceDialog.Provider = ({ children, installationServiceOption, product }) => {
    const [open, setOpen] = useState(false);
    const [optionsValue, setOptionsValue] = useState('');
    const onSubmit = useRef(() => {});

    const isInstallationService = product.layanan_instalasi && installationServiceOption.id !== null && installationServiceOption.uid !== null;

    return (
        <InstallationServiceDialogContext.Provider value={{
            open,
            setOpen,
            onSubmit,
            isInstallationService,
            installationServiceOption,
            optionsValue,
            setOptionsValue,
        }}
        >
            {children}
        </InstallationServiceDialogContext.Provider>
    );
};

export default InstallationServiceDialog;
