import makeStyles from '@material-ui/core/styles/makeStyles';
import { CreateBorder, CreateMargin, CreatePadding } from '@theme_mixins';
import { PRIMARY, WHITE } from '@theme_color';
import { FONT_14 } from '@theme_typography';

export default makeStyles((theme) => ({
    root: {
        width: '100%',
        ...CreateMargin(15, 0, 15, 0),
        ...CreateBorder('1px', 0, 0, 0, PRIMARY),
    },
    expandContainer: {
        boxShadow: 'none',
        borderRadius: 'none',
        margin: 0,
    },
    headerExpand: {
        padding: 0,
        height: 40,
        ...CreateBorder(0, 0, '1px', 0, PRIMARY),
    },
    bodyExpand: {
        ...CreatePadding(0, 0, 20, 0),
        margin: 0,
    },
    headerOpen: {
        borderBottom: 'none !important',
    },
    bodyOpen: {
        ...CreateBorder(0, 0, '1px', 0, PRIMARY),
    },
    icon: {
        fontSize: 16,
        color: PRIMARY,
    },
    descriptionHtml: {
        // fontFamily: 'Montserrat',
        fontSize: 12,
        margin: '0 auto',
        color: '#6B7280',
        '& span': {
            // fontFamily: 'Montserrat !important',
            fontSize: '14px !important',
        },
        // [theme.breakpoints.up('md')]: {
        //     width: 950,
        //     height: '100%',
        // },
        // [theme.breakpoints.up('sm')]: {
        //     width: 800,
        //     height: '100%',
        // },
        // [theme.breakpoints.down('sm')]: {
        //     width: 320,
        //     height: '100%',
        // },
        '& img': {
            width: '100%',
            height: '100%',
            [theme.breakpoints.up('md')]: {
                maxWidth: 800,
            },
            [theme.breakpoints.up('sm')]: {
                maxWidth: 650,
            },
            [theme.breakpoints.down('sm')]: {
                maxWidth: 300,
            },
        },
        // '& iframe': {
        //     width: '100%',
        //     height: 'auto',
        //     [theme.breakpoints.up('md')]: {
        //         maxWidth: 800,
        //     },
        //     [theme.breakpoints.up('sm')]: {
        //         maxWidth: 650,
        //     },
        //     [theme.breakpoints.down('sm')]: {
        //         maxWidth: 300,
        //     },
        // },
    },

    listLabel: {
        // ...FONT_REGULAR,
        ...FONT_14,
    },
    listValue: {
        // ...FONT_DEFAULT,
        ...FONT_14,
    },
    tabs: {
        boxShadow: 'none',
        backgroundColor: WHITE,
        alignItems: 'left',
        marginTop: '56px',
        borderBottom: '1px solid #E5E7EB',
        '& .tabsHeader': {
            letterSpacing: '0.75px',
            fontWeight: '600',
            color: '#9CA3AF',
            textTransform: 'capitalize',
            '& .MuiTab-wrapper': {
                fontSize: '14px',
                lineHeight: '20px',
            },
            '&.Mui-selected': {
                color: '#000000',
            },
            '&.MuiTab-root': {
                marginRight: '40px',
                padding: 0,
            },
        },
    },
    tabPanelContainer: {
        '& .MuiBox-root': {
            padding: '24px 0 24px 0',
        },
        '& .MuiListItem-gutters': {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    iframeWrapper: {
        '& iframe': {
            marginTop: '16px',
            width: '100%',
            height: 'auto',
            minHeight: '480px',
            border: 'none',
            borderRadius: '12px',
            [theme.breakpoints.down('sm')]: {
                minHeight: '320px',
            },
        },
    },
}));
