/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import GridList from '@common_gridlist';
import Image from 'next/image';
import Link from 'next/link';
import { getThumborUrl } from '@root/src/helpers/thumborConfig';
import useStyles from './style';

const imgUrl = (width, height, url) => (
    `${getThumborUrl()}/unsafe/${width}x${height}/filters:format(webp)/${url}`);

function TabPanel(props) {
    const {
        children, value, index, ...other
    } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
            style={{ minHeight: 250 }}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const filterContentByDate = (data) => {
    const currentDate = new Date();

    return data.map((category) => {
        const filteredContent = category.content.filter((item) => {
            if (item.validFrom || item.validTo) {
                const validFrom = item.validFrom ? new Date(item.validFrom) : null;
                const validTo = item.validTo ? new Date(item.validTo) : null;

                if (validFrom && currentDate < validFrom) {
                    return false; // Exclude if current date is before validFrom
                }
                if (validTo && currentDate > validTo) {
                    return false; // Exclude if current date is after validTo
                }
            }
            return true; // Keep items with no validity checks or within range
        });

        return {
            ...category,
            content: filteredContent,
        };
    });
};

const CmsTab = (props) => {
    const {
        data: dataArray,
    } = props;
    const styles = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const data = filterContentByDate(dataArray);

    return (
        <div>
            <AppBar position="static" color="default" className={styles.tabs}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: '#000000',
                            height: '2px',
                        },
                    }}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {data.map((tab) => <Tab className="tabsHeader" label={tab.label} key={tab.id} />)}
                </Tabs>
            </AppBar>
            {
                data.map((tabData, index) => (
                    <TabPanel className={styles.tabPanelContainer} value={value} index={index}>
                        <GridList
                            data={tabData.content}
                            ItemComponent={({ imageUrl, redirectLink, text = '' }) => (
                                <div>
                                    <Link href={redirectLink}>
                                        <a>
                                            <Image
                                                src={imgUrl(180, 68, imageUrl)}
                                                width={180}
                                                height={68}
                                                quality={100}
                                                alt={imageUrl}
                                                className={styles.slideImage}
                                            />
                                            {
                                                text ? (
                                                    <div dangerouslySetInnerHTML={{ __html: text }} />
                                                ) : null
                                            }
                                        </a>
                                    </Link>
                                </div>
                            )}
                            className={styles.customGridClass}
                            gridItemProps={{ xs: 6, sm: 4, md: 3 }}
                        />
                    </TabPanel>
                ))
            }
        </div>
    );
};

export default CmsTab;
