/* eslint-disable no-underscore-dangle */
/* eslint-disable import/order */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
import React from 'react';
import Layout from '@layout';
import Error from '@modules/error/pages/default';
import { StripHtmlTags } from '@helper_text';
import { features, modules, debuging } from '@config';
import { useRouter } from 'next/router';
import TagManager from 'react-gtm-module';
import {
    getVendor, getCookies, setCookies, getVendor as getVendorCookies, getVendorCode,
} from '@helper_cookies';
import Loading from '@core_modules/product/pages/default/components/Loader';
import Header from '@core_modules/product/pages/default/components/header';
import generateSchemaOrg from '@modules/product/helpers/schema.org';
import {
    getProduct, getAvailableSource, addWishlist as mutationAddWishlist, getCustomizableOptions, setLastViewedProduct,
} from '@modules/product/services/graphql';
import { addCompare as mutationAddCompare } from '@modules/catalog/services/graphql';
// import { useQuery } from '@apollo/client';
// import { localCompare } from '@modules/theme/services/graphql/local';
import { getMonth, getYear } from '@root/core/helpers/date';
import { getHost } from '@helper_config';

import InstallationServiceDialog from './components/InstallationServiceDialog';

function useInstallationServiceOption(urlKey) {
    const [installationServiceOption, setInstallationServiceOption] = React.useState({
        id: null,
        uid: null,
    });

    const customizableOptionsResult = getCustomizableOptions(urlKey);

    React.useEffect(() => {
        const { data } = customizableOptionsResult;
        const option = data?.products?.items?.[0].options?.[0];
        if (option) {
            setInstallationServiceOption({
                id: option.option_id,
                uid: option.textField.uid,
            });
        }
    }, [customizableOptionsResult.data]);

    return installationServiceOption;
}

const ContentDetail = ({
    t, product,
    Content,
    isLogin,
    urlKey,
    storeConfig,
    vendor,
    vendorCode,
}) => {
    const route = useRouter();
    const item = product.items[0];
    const [setLastViewed] = setLastViewedProduct();
    const [vendorData] = item.vendor_product_data;
    const reviewValue = parseInt(item.review.rating_summary, 0) / 20;

    const itemAvailableSource = getAvailableSource({ productId: item.id });
    const installationServiceOption = useInstallationServiceOption(urlKey);

    React.useEffect(() => {
        let index = 0;
        let categoryProduct = '';
        let categoryOne = '';
        // eslint-disable-next-line no-unused-expressions
        item.categories.length > 0 && (
            categoryOne = item.categories[0].name,
            item.categories.map(({ name }, indx) => {
                if (indx > 0) categoryProduct += `/${name}`;
                else categoryProduct += name;
            })
        );
        const tagManagerArgs = {
            dataLayer: {
                pageName: item.name,
                pageType: 'product',
                ecommerce: {
                    detail: {
                        product: [{
                            name: item.name,
                            id: item.sku,
                            price: item.price_range.minimum_price.regular_price.value || 0,
                            category: categoryProduct,
                            dimensions4: item.stock_status,
                            dimensions5: reviewValue,
                            dimensions6: item.review.reviews_count,
                            dimensions7: item.sale === 0 ? 'NO' : 'YES',
                        }],
                    },
                    currencyCode: item.price_range.minimum_price.regular_price.currency || 'USD',
                    impressions: [
                        ...item.related_products.map((val) => {
                            index += 1;
                            return ({
                                name: val.name,
                                id: val.sku,
                                category: categoryProduct,
                                price: val.price_range.minimum_price.regular_price.value,
                                list: `Related Products From ${item.name}`,
                                position: index,
                            });
                        }),
                        // ...item.upsell_products.map((val) => {
                        //     index += 1;
                        //     return ({
                        //         name: val.name,
                        //         id: val.sku,
                        //         category: categoryProduct,
                        //         price: val.price_range.minimum_price.regular_price.value,
                        //         list: `Related Products From ${item.name}`,
                        //         position: index,
                        //     });
                        // }),
                    ],
                },
                event: 'impression',
                eventCategory: 'Ecommerce',
                eventAction: 'Impression',
                eventLabel: item.name,
            },
        };

        // GA 4 dataLayer
        const tagManagerArgsGA4 = {
            dataLayer: {
                pageName: item.name,
                pageType: 'product',
                ecommerce: {
                    items: [
                        {
                            item_name: item.name,
                            item_id: item.sku,
                            price: item.price_range.minimum_price.regular_price.value || 0,
                            item_category: categoryOne,
                            currency: item.price_range.minimum_price.regular_price.currency || 'USD',
                            item_stock_status: item.stock_status,
                            item_reviews_score: reviewValue,
                            item_reviews_count: item.review.reviews_count,
                            item_sale_product: item.sale === 0 ? 'NO' : 'YES',
                        },
                    ],
                },
                event: 'view_item',
            },
        };
        // Clear the previous ecommerce object.
        TagManager.dataLayer({ dataLayer: { ecommerce: null } });
        TagManager.dataLayer(tagManagerArgs);
        // Clear the previous ecommerce object.
        TagManager.dataLayer({ dataLayer: { ecommerce: null } });
        TagManager.dataLayer(tagManagerArgsGA4);

        // clickstream
        // eslint-disable-next-line no-underscore-dangle
        if (storeConfig?.enabled_clickstream === '1' && typeof window !== 'undefined' && window?._swan?.productViewed) {
            // eslint-disable-next-line no-underscore-dangle, no-unused-expressions
            window._swan.productViewed({ productId: item.sku });
        }
    }, []);

    // const client = useApolloClient();

    const bannerData = [];
    if (item.media_gallery.length > 0) {
        const arrayGallery = [...item.media_gallery];
        const sortedMediaGallery = arrayGallery.sort((a, b) => a.position - b.position);
        // eslint-disable-next-line array-callback-return
        sortedMediaGallery.map((media) => {
            bannerData.push({
                link: '#',
                imageUrl: media.url_original,
            });
        });
    } else {
        bannerData.push({
            link: '#',
            imageUrl: item.image.url_original,
        });
    }

    const STORE_PICKUP = 'store_pickup';
    const DELIVERY = 'delivery';
    const [selectedShippingMethod, setSelectedShippingMethod] = React.useState(DELIVERY);
    const [openOption, setOpenOption] = React.useState(false);
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [openShare, setOpenShare] = React.useState(false);
    const [openImageDetail, setOpenImageDetail] = React.useState(false);
    const [banner, setBanner] = React.useState(bannerData);
    const [price, setPrice] = React.useState({
        priceRange: item.price_range,
        priceTiers: item.price_tiers,
        // eslint-disable-next-line no-underscore-dangle
        productType: item.__typename,
        specialFromDate: item.special_from_date,
        specialToDate: item.special_to_date,
        vendorCost: item.vendor_product_data[0].vendor_cost,
        vendorSpecialPrice: item.vendor_product_data[0].vendor_special_price,
        flashSalePrice: item.vendor_product_data[0].flash_sale_price,
        flashSaleFrom: item.vendor_product_data[0].flash_sale_from,
        flashSaleTo: item.vendor_product_data[0].flash_sale_to,
        vendorFlashSalePercent: item.vendor_product_data[0].flash_sale_percent,
        vendorFlashSaleDiscountAmount: item.vendor_product_data[0].flash_sale_discount_amount,
    });
    const [wishlist, setWishlist] = React.useState(false);

    const [addWishlist] = mutationAddWishlist();
    const [addCompare] = mutationAddCompare();
    // const { data: dataCompare, client } = useQuery(localCompare);

    React.useEffect(() => {
        if (item.layanan_instalasi) {
            setSelectedShippingMethod(DELIVERY);
        }
    }, [item.layanan_instalasi]);

    React.useEffect(() => {
        if (isLogin && isLogin === 1 && item.id) {
            setLastViewed({
                variables: {
                    product_id: item.id,
                },
            });
        }
    }, [item.id]);

    const handleWishlist = () => {
        if (isLogin && isLogin === 1) {
            addWishlist({
                variables: {
                    productId: item.id,
                },
            }).then(async () => {
                // clickstream
                if (storeConfig?.enabled_clickstream === '1' && typeof window !== 'undefined' && window?._swan?.productAddedToWishlist) {
                    // eslint-disable-next-line no-underscore-dangle, no-unused-expressions
                    window._swan.productAddedToWishlist({ productId: item.sku });
                }
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'addToWishlist',
                        eventLabel: item.name,
                        label: item.name,
                        ecommerce: {
                            currencyCode: item.price_range.minimum_price.regular_price.currency || 'USD',
                            add: {
                                products: [{
                                    name: item.name,
                                    id: item.sku,
                                    price: item.price_range.minimum_price.regular_price.value || 0,
                                    category: item.categories.length > 0 ? item.categories[0].name : '',
                                    list: item.categories.length > 0 ? item.categories[0].name : '',
                                    dimensions4: item.stock_status,
                                }],
                            },
                        },
                    },
                });

                // GA 4 dataLayer
                TagManager.dataLayer({
                    dataLayer: {
                        ecommerce: {
                            action: {
                                items: [
                                    {
                                        currency: item.price_range.minimum_price.regular_price.currency,
                                        item_name: item.name,
                                        item_id: item.sku,
                                        price: item.price_range.minimum_price.regular_price.value || 0,
                                        item_category: item.categories.length > 0 ? item.categories[0].name : '',
                                        item_stock_status: item.stock_status,
                                    },
                                ],
                            },
                        },
                        event: 'add_to_wishlist',
                    },
                });
                await setWishlist(!wishlist);
                await window.toastMessage({ open: true, variant: 'success', text: t('common:message:feedSuccess') });
                route.push('/wishlist');
            }).catch((e) => {
                window.toastMessage({
                    open: true,
                    variant: 'error',
                    text: debuging.originalError ? e.message.split(':')[1] : t('common:message:feedFailed'),
                });
            });
        } else {
            window.toastMessage({
                open: true,
                variant: 'warning',
                text: t('catalog:wishlist:addWithoutLogin'),
            });
        }
    };
    const getUid = () => {
        const uidCompare = getCookies('uidCompare');
        if (uidCompare) {
            return uidCompare;
        }
        const newUid = `${new Date().getTime().toString(36)}${(Math.random()).toString(36).replace('.', '')}`;
        setCookies('uidCompare', newUid);
        return newUid;
    };

    const handleCompare = () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'addToCompare',
                eventLabel: item.name,
                label: item.name,
                ecommerce: {
                    currencyCode: item.price_range.minimum_price.regular_price.currency || 'USD',
                    add: {
                        products: [{
                            name: item.name,
                            id: item.sku,
                            price: item.price_range.minimum_price.regular_price.value || 0,
                            category: item.categories.length > 0 ? item.categories[0].name : '',
                            list: item.categories.length > 0 ? item.categories[0].name : '',
                            dimensions4: item.stock_status,
                        }],
                    },
                },
            },
        });
        addCompare({
            variables: {
                productId: item.id,
                vendorId: vendorData.vendor_id,
                uid: isLogin ? '' : getUid(),
            },
        }).then(async () => {
            // client.writeQuery({ query: localCompare, data: { totalCompare: dataCompare.totalCompare + 1 } });
            await window.toastMessage({ open: true, variant: 'success', text: t('common:message:compareSuccess') });
            setTimeout(() => { route.push('/catalog/product_compare'); }, 500);
        }).catch((e) => {
            window.toastMessage({
                open: true,
                variant: 'error',
                text: debuging.originalError ? e.message.split(':')[1] : t('common:message:compareFailed'),
            });
        });
    };

    let expandData = [];
    if (item.description.html) {
        expandData = [
            ...expandData,
            {
                title: 'Spesifikasi Produk',
                type: 'html',
                content: item.description.html,
                video: item?.link_video || '',
            },
        ];
    }
    if (item.more_info && item.more_info.length > 0) {
        const customMoreInfoData = [
            {
                label: 'PRODUCT WEIGHT',
                value: item?.custom_weight ? `${item.custom_weight} kg` : 'NO',
            },
            {
                label: 'DIMENSION PRODUCT WIDTH',
                value: item?.product_dimension_width ? `${item.product_dimension_width} cm` : 'NO',
            },
            {
                label: 'DIMENSION PRODUCT LENGTH',
                value: item?.product_dimension_length ? `${item.product_dimension_length} cm` : 'NO',
            },
            {
                label: 'DIMENSION PRODUCT HEIGHT',
                value: item?.product_dimension_height ? `${item.product_dimension_height} cm` : 'NO',
            },
        ];

        expandData = [
            ...expandData,
            {
                title: 'Informasi Produk',
                type: 'array',
                content: [
                    ...item.more_info.filter((info) => info.label !== 'PRODUCT WEIGHT'
                        && info.label !== 'DIMENSION PRODUCT WIDTH'
                        && info.label !== 'DIMENSION PRODUCT LENGTH'
                        && info.label !== 'DIMENSION PRODUCT HEIGHT'),
                    ...customMoreInfoData,
                ],
            },
        ];
    }
    const relateData = item.related_products.map((val) => ({
        ...val,
        name: val.name,
        link: val.url_key,
        imageSrc: val.small_image.url,
        price: val.price_range.minimum_price.regular_price.value,
    }));
    let breadcrumbsData = [];
    if (typeof window !== 'undefined') {
        const lastCategory = getCookies('lastCategory');
        const cat = item.categories.filter(({ url_path }) => url_path === lastCategory);
        if (cat.length > 0) {
            if (cat[0].breadcrumbs && cat[0].breadcrumbs.length > 0) {
                breadcrumbsData = cat[0].breadcrumbs.map((bc) => ({
                    label: bc.category_name,
                    link: `/${bc.category_url_path}`,
                    active: false,
                    id: bc.category_id,
                }));
            }
            breadcrumbsData.push({
                label: cat[0].name,
                link: `/${cat[0].url_path}`,
                active: false,
                id: cat[0].id,
            });
        }

        breadcrumbsData.push({
            label: item.name,
            link: '#',
            active: true,
        });
    }

    const handleOption = () => {
        const { productAvailableToCart } = features;
        // eslint-disable-next-line no-underscore-dangle
        if (productAvailableToCart[item.__typename]) {
            setOpenOption(true);
        } else {
            window.toastMessage({
                variant: 'warning',
                text: t('product:productNotAvailable'),
                open: true,
            });
        }
    };

    const handleOpenImageDetail = () => {
        setOpenImageDetail(!openImageDetail);
    };

    const getStockVendor = () => {
        const availableSource = itemAvailableSource.data?.getAvailableSource?.list;
        if (availableSource && availableSource.length) {
            const obj = availableSource.find((e) => e.vendor_id === getVendorCookies());
            return (obj && obj.stock_qty) || 0;
        }
        return 0;
    };

    const handleYellowAiChat = () => {
        if (typeof window !== 'undefined') {
            let loginObj = {};
            if (isLogin && isLogin === 1) {
                loginObj = window?.ymConfig?.payload?.loginObj || {};
            }

            window.YellowMessengerPlugin.init({
                triggerJourney: 'ask-product_cyryjk',
                payload: {
                    isLogin: isLogin && isLogin === 1,
                    loginObj,
                    storeId: `${getVendorCode() || vendorCode}`,
                    productId: item?.sku || '',
                    productName: item.name,
                    productURL: `${getHost()}/${item.url_key}`,
                    productPrice: item?.vendor_product_data[0]?.vendor_special_price || item?.vendor_product_data[0]?.vendor_cost || 0,
                    productImageUrl: item.image.url_original,
                },
            });

            window.YellowMessengerPlugin.openBot();
        }
    };

    return (
        <InstallationServiceDialog.Provider installationServiceOption={installationServiceOption} product={item}>
            <Content
                data={item}
                weltpixel_labels={item.weltpixel_labels}
                getAvailableSource={itemAvailableSource}
                t={t}
                openOption={openOption}
                handleOption={handleOption}
                setOpenOption={setOpenOption}
                setBanner={setBanner}
                setPrice={setPrice}
                openShare={openShare}
                setOpenShare={setOpenShare}
                route={route}
                banner={banner}
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
                breadcrumbsData={breadcrumbsData}
                price={price}
                handleWishlist={handleWishlist}
                handleCompare={handleCompare}
                reviewValue={reviewValue}
                wishlist={wishlist}
                expandData={expandData}
                relateData={relateData}
                features={features}
                config={modules.catalog.pdp}
                openImageDetail={openImageDetail}
                handleOpenImageDetail={handleOpenImageDetail}
                STORE_PICKUP={STORE_PICKUP}
                DELIVERY={DELIVERY}
                selectedShippingMethod={selectedShippingMethod}
                setSelectedShippingMethod={setSelectedShippingMethod}
                isLogin={isLogin}
                getStockVendor={getStockVendor}
                storeConfig={storeConfig}
                vendor={vendor}
                handleYellowAiChat={handleYellowAiChat}
            />
            <InstallationServiceDialog />
        </InstallationServiceDialog.Provider>
    );
};

const PageDetail = (props) => {
    let product = {};
    const {
        slug, Content, t, isLogin, pageConfig, CustomHeader, vendorId, vendorCode, storeConfig,
    } = props;
    const {
        loading, data, error,
    } = getProduct(slug[0], getVendor() || vendorId);

    if (error || loading || !data) {
        return (
            <Layout pageConfig={{}} CustomHeader={CustomHeader ? <CustomHeader /> : <Header />} {...props}>
                <Loading />
            </Layout>
        );
    }
    if (data) {
        product = data.products;
        if (product.items.length === 0) return <Error statusCode={404} {...props} />;
    }

    const schemaOrg = generateSchemaOrg(product.items[0]);

    const config = {
        title: product.items.length > 0
            ? `Jual ${product?.items[0]?.name || ''}`
            : '',
        bottomNav: true,
        header: 'absolute', // available values: "absolute", "relative", false (default)
        pageType: 'product',
        ogContent: {
            description: {
                type: 'meta',
                value: `Beli ${product.items.length > 0 ? product.items[0].name : ''} Original dan Resmi di Mitra10. Jasa Instalasi ✓ Cicilan 0% ✓ Garansi Servis ✓ Gratis Ongkir ✓ Nikmati juga Keuntungan Jadi Member Mitra10 Belanja Perabot Rumah dan Bangunan Aman di Mitra10. ${getMonth()} ${getYear()}`,
            },
            'og:image': product.items[0].small_image.url_original,
            'og:image:type': 'image/jpeg',
            'og:description': StripHtmlTags(product.items[0].description.html),
            'og:image:width': features.imageSize.product.width,
            'og:image:height': features.imageSize.product.height,
            'og:image:alt': product.items[0].name || '',
            'og:type': 'product',
            'product:availability': product.items[0].stock_status,
            'product:category': product.items[0].categories && product.items[0].categories.length > 0 && product.items[0].categories[0].name,
            'product:condition': 'new',
            'product:price:currency': product.items[0].price_range.minimum_price.final_price.currency,
            'product:price:amount': product.items[0]?.vendor_product_data[0]?.vendor_special_price || product.items[0]?.vendor_product_data[0]?.vendor_cost || 0,
        },
        schemaOrg,
        metaTitle: `Jual ${product.items.length > 0 ? product.items[0].name : ''} Original dan Berkualitas | Mitra10 ${getMonth()} ${getYear()}`,
        keywords: product.items[0] ? product.items[0].meta_keyword : '',
        description: product.items[0] ? product.items[0].meta_description : '',
    };

    return (
        <Layout
            pageConfig={pageConfig || config}
            CustomHeader={CustomHeader ? <CustomHeader /> : <Header />}
            {...props}
            productId={product?.items?.[0]?.sku}
        >
            <ContentDetail
                product={product}
                t={t}
                Content={Content}
                isLogin={isLogin}
                urlKey={slug[0]}
                storeConfig={storeConfig}
                vendor={vendorId}
                vendorCode={vendorCode}
            />
        </Layout>
    );
};

export default PageDetail;
