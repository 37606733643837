/* eslint-disable react/jsx-one-expression-per-line */
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { ORANGE_FLASHSALE } from '@theme_color';

import Box from '@material-ui/core/Box';
import Typography from '@common_typography';

const useStyles = makeStyles(() => ({
    flashBox: {
        borderRadius: '8px',
        marginBottom: '15px',
        padding: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '@media (min-width: 320px )': {
            '& h2': {
                fontSize: 14,
                color: 'white',
            },
        },
        '@media (min-width: 768px )': {
            marginTop: 25,
            '& h2': {
                fontSize: 16,
            },
        },
        '@media (min-width: 1440px )': {
            '& h2': {
                fontSize: 18,
            },
        },
    },
    flashTime: {
        color: 'white',
        fontSize: 14,
        '&.MuiTypography-body1': {
            fontSize: '14px !important',
        },
    },
}));

const View = (props) => {
    const styles = useStyles();
    const { t, time } = props;

    const getTextTime = (number) => (number > 9 ? number : (`0${number || 0}`));

    return (
        <Box className={classNames(styles.flashBox)} bgcolor={ORANGE_FLASHSALE}>
            <div className="col-md-6">
                <Typography variant="h2" type="bold" letter="none">
                    FLASH SALE
                </Typography>
            </div>
            <div className="col-md-6">
                <Typography className={styles.flashTime} variant="p" align="right">
                    {t('home:endsIn')} {' '}
                    {getTextTime(time && time.days)} {' : '}
                    {getTextTime(time && time.hours)} {' : '}
                    {getTextTime(time && time.minutes)} {' : '}
                    {getTextTime(time && time.seconds)}
                </Typography>
            </div>
        </Box>
    );
};

export default View;
