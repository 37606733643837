/* eslint-disable max-len */
import Layout from '@layout';
import { StripHtmlTags } from '@helper_text';
import generateSchemaOrg from '@core_modules/catalog/helpers/schema.org';
import { getMonth, getYear } from '@root/core/helpers/date';
import { getCategory, getTopSellerCategoryProducts } from '../../services/graphql';

const Page = (props) => {
    const {
        Content, vendorId, categoryId, storeConfig, SkeletonView, pageConfig = {}, ErrorView, ...other
    } = props;
    const { loading, data } = getCategory({
        productSize: 10,
        id: categoryId,
    });

    const { data: dataTopSeller } = getTopSellerCategoryProducts({
        variables: {
            categoryId: Number(categoryId),
            selectedStore: Number(vendorId),
        },
    });

    const topSellerProducts = dataTopSeller?.getTopSellerCategoryProducts || [];

    const ogContent = {};
    let config = {};
    let schemaOrg = null;
    if (data && data.categoryList[0]) {
        const category = data.categoryList[0];
        const metaTitle = `Jual ${data?.categoryList[0]?.name} Original dan Berkualitas | Mitra10 ${getMonth()} ${getYear()}`;
        const title = data?.categoryList[0]?.meta_title
            ? `${data?.categoryList[0]?.meta_title || ''}`
            : `Harga ${data?.categoryList[0]?.name || ''} Terbaik dan Original ${getMonth()} ${getYear()}`;
        schemaOrg = generateSchemaOrg(category, storeConfig);
        if (data.categoryList[0].description) {
            ogContent.description = StripHtmlTags(data.categoryList[0].description);
        }
        config = {
            title: loading ? '' : title,
            headerTitle: data && data?.categoryList[0].name,
            header: 'relative', // available values: "absolute", "relative", false (default)
            bottomNav: 'browse',
            pageType: 'category',
            metaTitle: data && data?.categoryList[0]?.meta_title ? `${data.categoryList[0].meta_title}` : metaTitle,
            keywords: data && data.categoryList[0] ? data.categoryList[0].meta_keywords : '',
            description: `Beli ${data?.categoryList[0]?.name} Original dan Resmi di Mitra10. Jasa Instalasi ✓ Cicilan 0% ✓ Garansi Servis ✓ Gratis Ongkir ✓ Nikmati juga Keuntungan Jadi Member Mitra10 Belanja Perabot Rumah dan Bangunan Aman di Mitra10. ${getMonth()} ${getYear()}`,
            ogContent,
            schemaOrg,
            canonical: data?.categoryList[0]?.seo_canonical || '',
            isPlpTitle: true,
        };
    }
    if (loading && !data) {
        return (
            <Layout {...props} pageConfig={config}>
                <SkeletonView />
            </Layout>
        );
    }

    if (data && !data.categoryList[0]) {
        return <ErrorView statusCode={404} {...props} />;
    }
    return (
        <Layout {...props} pageConfig={config || pageConfig}>
            <Content
                categoryId={categoryId}
                storeConfig={storeConfig}
                data={data}
                vendorId={vendorId}
                topSellerProducts={topSellerProducts}
                {...other}
            />
        </Layout>
    );
};

export default Page;
