import Error from '@modules/error/pages/default';
import { getResolver as getLocalResolver } from '@helper_localstorage';
import Layout from '@layout';
import { getResolver, getRedirectUrl } from '@modules/slug/services/graphql';
import { useEffect } from 'react';
import { getHost } from '@root/core/helpers/config';

const ContainerResolver = (props) => {
    const {
        slug, CategoryPage, ProductPage, CmsPage, resolver, contentProps, storeConfig, ...other
    } = props;
    const [redirectTo] = getRedirectUrl({
        onCompleted: (res) => {
            if (res?.redirectUrlCategory?.newUrl) {
                window.location.replace(res?.redirectUrlCategory?.newUrl);
            }
        },
    });
    useEffect(() => {
        if (resolver.type === 'CATEGORY') {
            const oldUrl = slug.join('/');
            const combineFullUrl = `${getHost()}/${oldUrl}`;
            redirectTo({
                variables: {
                    oldurl: combineFullUrl,
                },
            });
        }
    }, [resolver, slug]);

    if (resolver.type === 'CATEGORY') {
        return <CategoryPage {...contentProps} categoryId={resolver.id} {...other} />;
    }
    if (resolver.type === 'PRODUCT') {
        return <ProductPage {...contentProps} {...other} />;
    }
    if (resolver.type === 'CMS_PAGE') {
        return <CmsPage {...contentProps} {...other} />;
    }
    return <Error statusCode={404} {...contentProps} />;
};

const Slug = (props) => {
    const {
        slug, storeConfig, ProductLoader, CategorySkeleton, LoadingView, t, ...other
    } = props;

    const cmsPages = storeConfig && storeConfig.cms_page ? storeConfig.cms_page.split(',') : [];
    let url = slug.join('/');
    // suffix based on storeConfig
    const suffix = (storeConfig || {}).category_url_suffix || '.html';

    // for cms pages, no need to add suffix
    url += cmsPages.find((cmsPage) => cmsPage === url) ? '' : suffix;
    const { error, loading, data } = getResolver(url);

    const config = {
        ogContent: {},
    };

    if (error) return <Error statusCode={500} />;
    if (loading) {
        return (
            <Layout storeConfig={storeConfig} pageConfig={config} t={t}>
                <LoadingView open />
            </Layout>
        );
    }
    const resolver = data.urlResolver ? data.urlResolver : {};
    const contentProps = { slug, storeConfig };
    return <ContainerResolver slug={slug} resolver={resolver} {...other} contentProps={contentProps} />;
};

const SlugContainer = (props) => {
    const { slug, storeConfig } = props;
    let localResolver;
    if (typeof window !== 'undefined') {
        const contentProps = { slug, storeConfig };
        let key = '';
        for (let index = 0; index < slug.length; index += 1) {
            const element = slug[index];
            key += `/${element}`;
        }
        localResolver = getLocalResolver();
        const resolver = localResolver[key];
        if (resolver && resolver.type) {
            resolver.relative_url = key;
            return <ContainerResolver resolver={resolver} {...props} contentProps={contentProps} />;
        }
    }
    return <Slug slug={slug} {...props} />;
};

export default SlugContainer;
