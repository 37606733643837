import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(() => ({
    container: {
        '& img': {
            width: 'auto',
            maxWidth: '100%',
        },
    },
}));
