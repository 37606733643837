import React /* , { useEffect }  */ from 'react';
import GridList from '@common_gridlist';
import dynamic from 'next/dynamic';
import Typography from '@common_typography';
import FlashSaleTimer from '@common_flashsaletimer';
import FlashSaleTimerView from '@common_flashsaletimer/view/simple';
import classNames from 'classnames';
import { modules } from '@config';
import Filter from '@modules/catalog/plugin/ProductList/components/Filter';
import ProductItem from '@modules/catalog/plugin/ProductItem/index';
import LabelView from '@core_modules/catalog/plugin/ProductItem/components/LabelView';
import Sort from '@modules/catalog/plugin/ProductList/components/FilterDesktop/sort';
import Pagination from '@material-ui/lab/Pagination';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CarouselV2 from '@commons/CarouselV2';
import useStyles from './style';
import FilterDesktop from './FilterDesktop';

const ProductCard = dynamic(() => import('@modules/home/pages/default/components/ProductCard'), { ssr: false });

const Content = (props) => {
    const styles = useStyles();
    const {
        query, showTabs, customFilter, elastic, t, isBrandDetailPage = false,
        aggregations, setFiltervalue, category, defaultSort, config, TabView,
        products, categoryPath, renderEmptyMessage, ProductListSkeleton, loading,
        loadmore, handleLoadMore, dataTabs, onChangeTabs, topSellerProducts = [], pageSize,
        handlePageSize, ...other
    } = props;

    const { storeConfig } = other;
    let flashSaleData = null;
    let isFlashSale = null;
    if (products && products.items.length > 1) {
        const [productItem] = products.items;
        [flashSaleData] = productItem.vendor_product_data;
        const startFlashSaleDate = new Date(flashSaleData.flash_sale_from).getTime();
        const endFlashSaleDate = new Date(flashSaleData.flash_sale_to).getTime();
        isFlashSale = Date.now() > startFlashSaleDate && Date.now() < endFlashSaleDate && flashSaleData.show_countdown;
    }

    // const handleScroll = () => {
    //     // To get page offset of last user
    //     const lastUserLoaded = document.querySelector(
    //         '.grid-item:last-child',
    //     );
    //     if (lastUserLoaded) {
    //         const lastUserLoadedOffset = lastUserLoaded.offsetTop + lastUserLoaded.clientHeight;
    //         const pageOffset = window.pageYOffset + window.innerHeight;
    //         if (pageOffset > lastUserLoadedOffset && !loadmore && products.items.length < products.total_count) {
    //             handleLoadMore();
    //         }
    //     }
    // };

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // });

    return (
        <div className={styles.containerWrap}>
            {showTabs ? (
                <div className="hidden-desktop">
                    {
                        !isBrandDetailPage ? (
                            <TabView
                                // eslint-disable-next-line radix
                                value={query.category_id ? query.category_id : 0}
                                data={category}
                                onChange={(e, value) => setFiltervalue({ ...query, ...{ category_id: value } })}
                                {...other}
                            />
                        ) : null
                    }
                </div>
            ) : null}
            <div className={modules.catalog.productListing.drawerFilterOnDesktop.enabled ? 'hidden-desktop' : ''}>
                <Filter
                    filter={customFilter || aggregations}
                    defaultSort={JSON.stringify(defaultSort)}
                    filterValue={query}
                    setFiltervalue={setFiltervalue}
                    isSearch={!!config.search}
                    isBrandDetailPage={isBrandDetailPage}
                    products={products}
                    renderEmptyMessage={renderEmptyMessage}
                    loading={loading}
                    t={t}
                    {...other}
                />
            </div>
            <div>
                <div className="col-sm-12 col-lg-12">
                    {isFlashSale ? <FlashSaleTimer flashSaleData={flashSaleData} View={FlashSaleTimerView} /> : null}
                </div>
                {
                    topSellerProducts.length > 0 ? (
                        <div className={classNames(styles.sliderWrapper)}>
                            <Typography size={14} type="bold" variant="p" className={styles.topSellerTitle}>
                                {t('catalog:topSeller')}
                            </Typography>
                            <CarouselV2>
                                {topSellerProducts.map((product) => (
                                    <ProductCard
                                        key={product.product.id}
                                        {...product.product}
                                        vendor_product_data={product.vendor_product_data}
                                        customGtmClass="gtm_mitra10_cta_popular_product"
                                        storeConfig={storeConfig}
                                    />
                                ))}
                            </CarouselV2>
                        </div>
                    ) : null
                }
            </div>
            {modules.catalog.productListing.drawerFilterOnDesktop.enabled ? (
                <div className={classNames(styles.filterBtnContainer, 'hidden-mobile')}>
                    <Sort
                        filter={customFilter || aggregations}
                        defaultSort={JSON.stringify(defaultSort)}
                        filterValue={query}
                        setFiltervalue={setFiltervalue}
                        isSearch={!!config.search}
                        isBrandDetailPage={isBrandDetailPage}
                        t={t}
                        {...other}
                    />
                </div>
            ) : null}

            <div className="row">
                {modules.catalog.productListing.drawerFilterOnDesktop.enabled
                    ? (
                        <div className="col-sm-12 col-lg-2 hidden-mobile">
                            <FilterDesktop
                                filter={customFilter || aggregations}
                                defaultSort={JSON.stringify(defaultSort)}
                                filterValue={query}
                                setFiltervalue={setFiltervalue}
                                isSearch={!!config.search}
                                products={products}
                                isBrandDetailPage={isBrandDetailPage}
                                renderEmptyMessage={renderEmptyMessage}
                                loading={loading}
                                tabs={dataTabs}
                                t={t}
                                onChangeTabs={onChangeTabs}
                            />
                        </div>
                    )
                    : null}
                <div className={`col-sm-12 col-xs-12 col-lg-${modules.catalog.productListing.drawerFilterOnDesktop.enabled ? '10' : '12'}`}>
                    {modules.catalog.productListing.drawerFilterOnDesktop.enabled
                        ? (
                            <Typography variant="p" type="regular" className={classNames('hidden-mobile', styles.countProductTextDesktop)}>
                                {products.total_count}
                                {' '}
                                {t('catalog:product:name')}
                            </Typography>
                        ) : null}
                    <div className={styles.productContainer}>
                        {loading && <ProductListSkeleton />}
                        {!loading && (
                            <GridList
                                data={products.items}
                                ItemComponent={ProductItem}
                                className="grid"
                                itemProps={{
                                    categorySelect: categoryPath,
                                    LabelView,
                                    className: 'grid-item',
                                    ...other,
                                }}
                                gridItemProps={{ xs: 6, sm: 4, md: modules.catalog.productListing.drawerFilterOnDesktop.enabled ? 3 : 2 }}
                            />
                        )}
                        {(products.items.length === products.total_count) || loading
                            ? renderEmptyMessage(products.items.length, loading)
                            : null}
                        {/* {loadmore ? (
                            <div className={styles.divLoadMore}>
                                <Typography align="center" variant="span" type="bold" letter="uppercase" color="gray">
                                    Loading
                                </Typography>
                            </div>
                        ) : null} */}

                        {
                            !loading && products.items.length > 0 ? (
                                <div className={styles.paginationWrapper}>
                                    <Pagination
                                        className={styles.paginationStyle}
                                        color="primary"
                                        count={products?.page_info?.total_pages || 0}
                                        page={products?.page_info?.current_page || 0}
                                        onChange={handleLoadMore}
                                    />
                                    <div>
                                        <Select
                                            value={pageSize}
                                            onChange={handlePageSize}
                                        >
                                            <MenuItem value={12}>12</MenuItem>
                                            <MenuItem value={24}>24</MenuItem>
                                            <MenuItem value={48}>48</MenuItem>
                                            <MenuItem value={72}>72</MenuItem>
                                        </Select>
                                        <span>{t('catalog:pageSize')}</span>
                                    </div>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Content;
