/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import Router from 'next/router';
import classNames from 'classnames';
import { features } from '@config';
import Typography from '@common_typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Product from '../../../plugin/ProductList';
import useStyles from './style';

// sementara di comment dlu, untuk custom filter memakai aggregations product
// import { getFilter } from '../../../services/graphql';

const categoryTabs = (category) => {
    const data = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < category.length; index++) {
        data.push(category[index].name);
    }
    return data;
};

const CategoryPage = ({
    data, topSellerProducts, storeConfig, t, BannerView, BreadcrumbView, TabView, ...other
}) => {
    const styles = useStyles();
    const isDesktop = useMediaQuery('(min-width: 768px)');
    const [value] = React.useState(0);
    const categoryList = data.categoryList[0];
    let dataBanner = [];
    const handleChange = (event, newValue) => {
        Router.push(
            '/[...slug]',
            `/${categoryList.children[newValue - 1].url_path}`,
        );
    };
    if (categoryList.image_path) {
        dataBanner = [
            {
                imageUrl: categoryList.image_path,
                link: categoryList.url_path,
                description: categoryList.description,
            },
        ];
    }
    // sementara di comment dlu, untuk custom filter memakai aggregations product
    // const customFilter = getFilter(categoryList.id);
    let breadcrumbsData = [];
    if (categoryList.breadcrumbs && categoryList.breadcrumbs.length > 0) {
        breadcrumbsData = categoryList.breadcrumbs.map((bc) => ({
            label: bc.category_name,
            link: `/${bc.category_url_path}`,
            active: false,
            id: bc.category_id,
        }));
    }
    breadcrumbsData.push({
        label: categoryList.name,
        link: '#',
        active: true,
    });

    useEffect(() => {
        // eslint-disable-next-line no-underscore-dangle
        if (storeConfig?.enabled_clickstream === '1' && typeof window !== 'undefined' && window?._swan?.productListViewed) {
            // eslint-disable-next-line no-underscore-dangle
            window._swan.productListViewed({ productListId: categoryList.id });
        }
    }, []);

    return (
        <>
            <style jsx>
                {`
                    .cms-block-category :global(img) {
                        width: 100%;
                        max-width: 100%;
                    }
                `}
            </style>
            <div className={styles.container}>
                <div className={classNames(styles.breadcrumbs, 'hidden-mobile')}>
                    <BreadcrumbView data={breadcrumbsData} />
                </div>
                <div className={styles.headContainer}>
                    {dataBanner.length > 0
                        ? (
                            <div>
                                <BannerView
                                    src={dataBanner[0].imageUrl}
                                    width={features.imageSize.category.width}
                                    height={features.imageSize.category.height}
                                    showArrow={dataBanner.length > 1}
                                    style={{ width: '100%', height: 'auto' }}
                                />
                                {/* <span className={styles.centered}>
                                    {categoryList.name}
                                </span> */}
                            </div>
                        ) : null}
                </div>
                <div className={classNames(styles.breadcrumbs, 'hidden-desktop')}>
                    <BreadcrumbView data={breadcrumbsData} />
                </div>
                {
                    isDesktop ? (
                        <Typography variant="h1" className={styles.categoryName}>
                            {categoryList.name}
                        </Typography>
                    ) : null
                }
                <div className="hidden-desktop">
                    <TabView
                        data={categoryTabs(categoryList.children)}
                        onChange={handleChange}
                        value={value}
                    />
                </div>
                {
                    categoryList
                    && (categoryList.display_mode === 'PRODUCTS_AND_PAGE' || categoryList.display_mode === 'PAGE')
                    && categoryList.cms_block
                    && (
                        <div className="cms-block-category" dangerouslySetInnerHTML={{ __html: categoryList.cms_block.content }} />
                    )
                }
                {
                    categoryList
                    && (!categoryList.display_mode || categoryList.display_mode === 'PRODUCTS_AND_PAGE' || categoryList.display_mode === 'PRODUCTS')
                    && (
                        <>
                            <Product
                                // sementara di comment dlu, untuk custom filter memakai aggregations product
                                // customFilter={customFilter.loading ? [] : customFilter.data.getFilterAttributeOptions.data}
                                catId={categoryList.id}
                                categoryPath={categoryList.url_path}
                                catalog_search_engine={storeConfig.catalog_search_engine}
                                t={t}
                                category={categoryTabs(categoryList.children)}
                                dataTabs={categoryTabs(categoryList.children)}
                                onChangeTabs={handleChange}
                                storeConfig={storeConfig}
                                topSellerProducts={topSellerProducts}
                                defaultSort={{}}
                                {...other}
                            />
                            {categoryList.description
                                && (
                                    <div className={styles.seoFooter}>
                                        <div dangerouslySetInnerHTML={{ __html: categoryList.description }} />
                                    </div>
                                )}
                        </>
                    )
                }
            </div>
        </>
    );
};

export default CategoryPage;
